import http from '../../../libs/http';

const config = {
    ep: "ms-1/skills",
    reqEl: "skill",
    respEls: "skills",
    respEl: "skill",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale"
};

const cmsGetSkill = (context, resolve, reject, res) => {
    if (res && res['data'] && res['data'][config.respEl]){
        context.commit(config.storeRow, res['data'][config.respEl]);
        resolve(res['data']);
    } else {
        reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
    }
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                editModule: {id: 0 }
            },
            getters: {
                ...standardStore.getters,
                editModule: state => state.editModule
            },
            mutations: {
                ...standardStore.mutations,
                setEditModule(state, value){
                    state.editModule = value;
                },
                addBookToEditModule(state, value){
                    const newEditModule = JSON.parse(JSON.stringify(state.editModule));
                    if (!newEditModule.book_ids) newEditModule.book_ids = [];

                    newEditModule.book_ids.push(value)
                    state.editModule = newEditModule;
                }
            },
            actions: {
                ...standardStore.actions(config),
                addModule(context, mod){
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + "/" + mod.skill_id + "/modules";
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, {
                            skill_module: mod
                        })
                            .then(res => {
                                cmsGetSkill(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                saveModule(context, mod){
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + "/" + mod.skill_id + "/modules/"+mod.id;
                    return new Promise((resolve, reject) => {
                        http.api.client.patch(ep, {
                            skill_module: mod
                        })
                            .then(res => {
                                cmsGetSkill(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                deleteModuleBook(context, bookModule){
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + "/" + bookModule.skill_id + "/modules/"+bookModule.module_id+"/books/"+bookModule.book_id;
                    return new Promise((resolve, reject) => {
                        http.api.client.delete(ep)
                            .then(res => {
                                cmsGetSkill(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                addModuleBooks(context, bookModule){
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + "/" + bookModule.skill_id + "/modules/"+bookModule.module_id+"/books/"+bookModule.book_id;
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, bookModule)
                            .then(res => {
                                //Devo aggiungerlo anche a editModel
                                if (res && res['data'] && res['data'][config.respEl]){
                                    context.commit('addBookToEditModule', bookModule);
                                }
                                cmsGetSkill(context, resolve, reject, res);

                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                patchModuleBooks(context, {bookModule, doMutation}){
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + "/" + bookModule.skill_id + "/modules/"+bookModule.module_id+"/books/"+bookModule.book_id;
                    return new Promise((resolve, reject) => {
                        http.api.client.patch(ep, bookModule)
                            .then(res => {
                                if (doMutation) cmsGetSkill(context, resolve, reject, res);
                                else resolve(true);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                }
            }
        }
    }
}
