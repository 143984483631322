import tools from '../../../libs/tools';
import http from '../../../libs/http';

const config = {
    ep: "v3/theupdate",
    reqEl: "theupdate",
    respEls: "the_updates",
    respEl: "theupdate",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "lang"
};

const commonStore = (context, resolve, reject, res) => {
    if (res && res['data'] && res['data'][config.respEl]){
        context.commit(config.storeRow, res['data'][config.respEl]);
        resolve(res['data']);
    } else {
        reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
    }
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: standardStore.state,
            getters: standardStore.getters,
            mutations: standardStore.mutations,
            actions: {
                ...standardStore.actions(config),
                autoTranslate(context, {name, thu_id, trans_id}){
                    tools.report('autoTranslate ', name, thu_id, trans_id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/'+thu_id+'/translations/'+trans_id+'/translate';
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, {})
                            .then(res => {
                                commonStore(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });

                },
                weeklyCompose(context, {thu_id, blocks}){
                    tools.report('weeklyCompose ', thu_id, blocks);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/'+thu_id+'/weekly';
                    return new Promise((resolve, reject) => {
                        http.api.client.patch(ep, {blocks: blocks})
                            .then(res => {
                                commonStore(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });

                },
                normalize(context, thu_id){
                    tools.report('normalize ', thu_id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/'+thu_id+'/normalize';
                    return new Promise((resolve, reject) => {
                        http.api.client.patch(ep, {})
                            .then(res => {
                                if (res['data'] && res['data'][config.reqEl]){
                                    resolve(res['data'][config.reqEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });

                },
                addSection(context, {name, thu_id, trans_id}){
                    tools.report('addSection ', name, thu_id, trans_id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/'+thu_id+'/translations/'+trans_id+'/sections';
                    return new Promise((resolve, reject) => {
                        const payload = {
                            section: name
                        };
                        http.api.client.post(ep, payload)
                            .then(res => {
                                commonStore(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });

                },
                saveBlock(context, {block, thu_id, trans_id}){
                    tools.report('updateBlock ', block, thu_id, trans_id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/'+thu_id+'/translations/'+trans_id+'/blocks';
                    return new Promise((resolve, reject) => {
                        const payload = {
                            block: block
                        };
                        const verb = block.id ? http.api.client.patch : http.api.client.post;
                        verb(ep, payload)
                            .then(res => {
                                commonStore(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });

                },
                deleteBlock(context, {block_id, thu_id, trans_id}){
                    tools.report('deleteBlock ', block_id, thu_id, trans_id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/'+thu_id+'/translations/'+trans_id+'/blocks/'+block_id;
                    return new Promise((resolve, reject) => {
                        http.api.client.delete(ep)
                            .then(res => {
                                commonStore(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });

                },
                moveBlock(context, {block_id, thu_id, trans_id, where}){
                    const direction = (where === "up") ? "up" : "down";
                    tools.report('deleteBlock ', block_id, thu_id, trans_id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/'+thu_id+'/translations/'+trans_id+'/blocks/'+block_id+"/"+direction;
                    return new Promise((resolve, reject) => {
                        http.api.client.put(ep, {})
                            .then(res => {
                                commonStore(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });

                },
                deleteSection(context, {section_name, thu_id, trans_id}){
                    tools.report('deleteSection ', section_name, thu_id, trans_id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/'+thu_id+'/translations/'+trans_id+'/sections/'+section_name;
                    return new Promise((resolve, reject) => {
                        http.api.client.delete(ep)
                            .then(res => {
                                commonStore(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });

                },
                moveSection(context, {section_name, thu_id, trans_id, where}){
                    const direction = (where === "up") ? "up" : "down";
                    tools.report('moveSection ', section_name, thu_id, trans_id, where);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/'+thu_id+'/translations/'+trans_id+'/sections/'+section_name+"/"+direction;
                    return new Promise((resolve, reject) => {
                        http.api.client.put(ep, {})
                            .then(res => {
                                commonStore(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });

                },
                search(context, { page, limit, order, date_from, date_to, text, full = false }){
                    page = page || 1;
                    limit = limit || 10;
                    order = order || 1;
                    const payload = {
                        tags: [],
                        categories: [],
                        limit: limit,
                        order: order,
                        offset: (page - 1) * limit
                    };
                    if (date_from) {
                        payload['date_from'] = date_from;
                    }
                    if (date_to) {
                        payload['date_to'] = date_to;
                    }
                    if (text) {
                        payload['text'] = text;
                    }
                    tools.report('SEARCH THE UPDATES ', page, limit);

                    context.commit('searchReset');
                    context.commit(config.startLoading);
                    const ep = full ? http.getUrl(config.ep) + '/full_searches' : http.getUrl(config.ep) + '/searches';
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, payload)
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEls]) {
                                    if (!full) {
                                        context.commit('setRows', res['data'][config.respEls]);
                                    }

                                    if (res['data']['page'])
                                        context.commit('setPage', res['data']['page']);
                                    if (res['data']['pages'])
                                        context.commit('setPages', res['data']['pages']);

                                    resolve(res['data']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    if (config.stopLoading) {
                                        context.commit(config.stopLoading);
                                    }
                                }
                            );
                    });

                },
            }
        }
    }
}
