const List = () => import('../../views/tasks/import_company_subscriptions/List');
const Edit = () => import ('../../views/tasks/import_company_subscriptions/Edit');

export default {
    Create: {
        path: 'tasks/sub_corporate_import/new',
        name: 'tasks_import_company_subscriptions_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/tasks/sub_corporate_import',
            breadcrumb: [
                { label: 'TASKS' },
                { label: 'Importazione abbonamenti B2B', to: '/app/tasks/sub_corporate_import' },
                { label: 'Nuova' }],
        },
    },
    List: {
        path: 'tasks/sub_corporate_import',
        name: 'tasks_import_company_subscriptions_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'TASKS' }, { label: 'Importazione abbonamenti B2B' }],
        },
    }
}
