const View = () => import('../../views/stats/subscriptions/static/View');

export default {
    View: {
        path: 'stats/subs/history',
        name: 'stats_subs_history',
        exact: true,
        component: View,
        meta: {
            breadcrumb: [{ label: 'STATISTICHE' }, { label: 'Subscriptions' }],
        },
    }
}
