import HelpDesk from './helpdesk/store'

export default {
    init(standardStore){
        return {
            namespaced: true,
            state(){
                return {}
            },
            getters: {},
            mutations: {},
            actions: {},
            modules: {
                HelpDesk: HelpDesk.init(standardStore)
            }
        }
    }
}
