const List = () => import('../../views/cont/articles/List');
const Edit = () => import('../../views/cont/articles/Edit');

export default {
    Create: {
        path: 'cont/articles/new',
        name: 'cont_articles_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: "" },
        meta: {
            basePath: '/app/cont/articles',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Articoli', to: '/app/cont/articles' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'cont/articles/:id/:lang?',
        name: 'cont_articles_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id,
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/articles',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Articoli', to: '/app/cont/articles'},
                { label: 'Modifica Articoli' }],
        },
    },
    List: {
        path: 'cont/articles',
        name: 'cont_articles_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Articoli' }],
        },
    }
}
