import http from '../../libs/http';

const setAuthToken = (state, value) => {
    state.authToken = value;
    localStorage.setItem('auth_token', value);
};

const setUserData = (state, value) => {
    state.email = value.email;
    state.displayName = value.displayName;
    state.photo = value.photo;
    localStorage.setItem('user_email', value.email || '');
    localStorage.setItem('user_name', value.displayName || '');
    localStorage.setItem('user_photo', value.photo || '');
};

const setId = (state, value) => {
    state.id = value;
    localStorage.setItem('user_id', value);
};

const setRole = (state, value) => {
    state.role = value;
    localStorage.setItem('user_role', value);
};

export default {
    namespaced: true,
    state(){
        return {
            authToken: localStorage.getItem('auth_token'),
            email: localStorage.getItem('user_email'),
            displayName: localStorage.getItem('user_name'),
            photo: localStorage.getItem('user_photo'),
            role: localStorage.getItem('user_role'),
            id: localStorage.getItem('user_id')
        }
    },
    getters: {
        authToken: state => state.authToken,
        email: state => state.email,
        displayName: state => state.displayName,
        photo: state => state.photo,
        id: state => state.id,
        role: state => state.role,
        loggedIn: state => !!(state.authToken),
        username: state => {
            if (state.displayName) return state.displayName;
            if (state.email) return state.email;
            return "Anonimo";
        }
    },
    mutations: {
        setAuthToken,
        setUserData,
        setId,
        setRole,
        logout(state){
            setAuthToken(state, '');
            setUserData(state, {});
            setRole(state, '');
            setId(state, null);
        }
    },
    actions: {
        login(context, token){
            context.commit('setAuthToken', '');
            const payload = { firebase_jwt: token };
            const ep      = http.getUrl('/v1/auth/firebase');
            return new Promise((resolve, reject) => {
                http.api.client.post(ep, JSON.stringify(payload)).then(
                    res => {
                        if (res && res['data'] && res['data']['auth']){
                            const auth = res['data']['auth'];
                            context.commit('setId', auth['user_id']);
                            context.commit('setRole', auth['role']);
                            context.commit('setAuthToken', auth['access_token']);
                            resolve();
                        } else {
                            reject({
                               error: 'response-mismatch',
                               response: res
                            });
                        }
                    }
                ).catch(err => {
                    console.log("SERVER ERROR", err.response);
                    reject({
                        error: 'error-response',
                        response: err.response
                    });
                });
            })
        },
        logout(context){
            http.logout().then(() => {
                console.log("LOGGED OUT FROM FIREBASE");
                context.commit('logout');
            }).catch(err => {
                console.log("***UNABLE TO LOGOUT FROM FIREBASE", err);
            })

        }
    }
}
