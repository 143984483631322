import http from '../../../libs/http';

const config = {
    ep: "ms-1/paths",
    reqEl: "path",
    respEls: "paths",
    respEl: "path",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale"
};

const cmsGetPath = (context, resolve, reject, res) => {
    if (res && res['data'] && res['data'][config.respEl]){
        context.commit(config.storeRow, res['data'][config.respEl]);
        resolve(res['data']);
    } else {
        reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
    }
};
function deleteModuleContent(context, bookModule, ep){
    context.commit(config.startLoading);
    return new Promise((resolve, reject) => {
        http.api.client.delete(ep)
            .then(res => {
                cmsGetPath(context, resolve, reject, res);
            })
            .catch(err => {
                reject(http.formatError('server-error', err));
            })
            .finally(() => {
                if (config.stopLoading) {
                    context.commit(config.stopLoading);
                }
            });
    });
}

function addModuleContent(context, bookModule, ep, action){
    context.commit(config.startLoading);
    return new Promise((resolve, reject) => {
        http.api.client.post(ep, bookModule)
            .then(res => {
                //Devo aggiungerlo anche a editModel
                if (res && res['data'] && res['data'][config.respEl]){
                    context.commit(action, bookModule);
                }
                cmsGetPath(context, resolve, reject, res);

            })
            .catch(err => {
                reject(http.formatError('server-error', err));
            })
            .finally(() => {
                if (config.stopLoading) {
                    context.commit(config.stopLoading);
                }
            });
    });
}

function patchModuleContents(context, {bookModule, doMutation}, ep){
    context.commit(config.startLoading);
    return new Promise((resolve, reject) => {
        http.api.client.patch(ep, bookModule)
            .then(res => {
                if (doMutation) cmsGetPath(context, resolve, reject, res);
                else resolve(true);
            })
            .catch(err => {
                reject(http.formatError('server-error', err));
            })
            .finally(() => {
                if (config.stopLoading) {
                    context.commit(config.stopLoading);
                }
            });
    });
}
export default {
    init(standardStore){
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                editModule: {id: 0 }
            },
            getters: {
                ...standardStore.getters,
                editModule: state => state.editModule
            },
            mutations: {
                ...standardStore.mutations,
                setEditModule(state, value){
                    state.editModule = value;
                },
                addBookToEditModule(state, value){
                    const newEditModule = JSON.parse(JSON.stringify(state.editModule));
                    if (!newEditModule.book_ids) newEditModule.book_ids = [];

                    newEditModule.book_ids.push(value)
                    state.editModule = newEditModule;
                },
                addEpisodeToEditModule(state, value){
                    const newEditModule = JSON.parse(JSON.stringify(state.editModule));
                    if (!newEditModule.episode_ids) newEditModule.episode_ids = [];

                    newEditModule.episode_ids.push(value)
                    state.editModule = newEditModule;
                }
            },
            actions: {
                ...standardStore.actions(config),
                addModule(context, mod){
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + "/" + mod.path_id + "/modules";
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, {
                            path_module: mod
                        })
                            .then(res => {
                                cmsGetPath(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                saveModule(context, mod){
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + "/" + mod.path_id + "/modules/"+mod.id;
                    return new Promise((resolve, reject) => {
                        http.api.client.patch(ep, {
                            path_module: mod
                        })
                            .then(res => {
                                cmsGetPath(context, resolve, reject, res);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                deleteModuleBook(context, bookModule){
                    const ep = http.getUrl(config.ep) + "/" + bookModule.path_id + "/modules/"+bookModule.module_id+"/books/"+bookModule.book_id;
                    return deleteModuleContent(context, bookModule, ep)
                },
                deleteModuleEpisode(context, bookModule){
                    const ep = http.getUrl(config.ep) + "/" + bookModule.path_id + "/modules/"+bookModule.module_id+"/episodes/"+bookModule.episode_id;
                    return deleteModuleContent(context, bookModule, ep)
                },
                addModuleBooks(context, bookModule){
                    const ep = http.getUrl(config.ep) + "/" + bookModule.path_id + "/modules/"+bookModule.module_id+"/books/"+bookModule.book_id;
                    return addModuleContent(context, bookModule, ep, 'addBookToEditModule');
                },
                addModuleEpisodes(context, bookModule){
                    const ep = http.getUrl(config.ep) + "/" + bookModule.path_id + "/modules/"+bookModule.module_id+"/episodes/"+bookModule.episode_id;
                    return addModuleContent(context, bookModule, ep, 'addEpisodeToEditModule');
                },
                patchModuleBooks(context, {bookModule, doMutation}){
                    const ep = http.getUrl(config.ep) + "/" + bookModule.path_id + "/modules/"+bookModule.module_id+"/books/"+bookModule.book_id;
                    return patchModuleContents(context, {bookModule, doMutation}, ep);
                },
                patchModuleEpisodes(context, {bookModule, doMutation}){
                    const ep = http.getUrl(config.ep) + "/" + bookModule.path_id + "/modules/"+bookModule.module_id+"/episodes/"+bookModule.episode_id;
                    return patchModuleContents(context, {bookModule, doMutation}, ep);
                }
            }
        }
    }
}
