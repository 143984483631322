import http from '../../../libs/http';

const config = {
    ep: 'v1/articles',
    fetchListEp: 'v2/articles',
    reqEl: 'article',
    respEls: 'articles',
    respEl: 'article',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'l',
};

const rowUpdater = (context, res, resolve, reject) => {
    if (res && res['data'] && res['data'][config.respEl]) {
        if (config.storeRow) {
            context.commit(config.storeRow, res['data'][config.respEl]);
        }
        resolve(res['data'][config.respEl]);
    } else {
        reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
    }
};
export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                searched: [],
                searchedChapters: [],
            },
            getters: {
                ...standardStore.getters,
                searched: state => state.searched,
                searchedChapters: state => state.searchedChapters,
                article: state => id => {
                    return state.rows.find(row => row.id === id) || {};
                },

            },
            mutations: {
                ...standardStore.mutations,
                setSearched(state, values) {
                    if (values) state.searched = values;
                },
                setSearchedChapterEdited(state, id){
                    state.searchedChapters.forEach(c => {
                       if (c.id === id) c.edited = true;
                    });
                },
                setSearchedChapter(state, chapter){
                    state.searchedChapters.forEach(c => {
                        if (c.id === chapter.id){
                            Object.keys(c).forEach(k => {
                                c[k] = chapter[k];
                            })
                        }
                    });
                },
                setSearchedChapters(state, values) {
                    values = values || [];
                    values.forEach(val => {
                        val['edited'] = false;
                    })
                    state.searchedChapters = values;
                },
                clearSearch(state) {
                    state.searched = [];
                },
            },
            actions: {
                ...standardStore.actions(config),
                searchInChapters(context, { text, lang }) {
                    context.commit('startLoading');
                    const ep = '/v1/article_chapters/searches';
                    return new Promise((resolve, reject) => {
                        if (!text) {
                            context.commit('stopLoading');
                            context.commit('setSearchedChapters', []);
                            return new Promise(resolve);
                        }
                        http.api.client
                            .post(ep, JSON.stringify({ text }), { headers: { 'Accept-language': lang } })
                            .then(res => {
                                if (res['data']) {
                                    context.commit('setSearchedChapters', res.data);
                                    resolve(res.data);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    });
                },
                sortChapters(context, { chapters, article_id, lang }) {
                    context.commit('startLoading');
                    const ep = '/v2/articles/' + article_id + '/chapters';
                    return new Promise((resolve, reject) => {
                        http.api.client.put(ep, JSON.stringify({
                            chapters: chapters,
                        }), {
                            headers: {
                                'Accept-language': lang,
                            },
                        })
                            .then(res => {
                                rowUpdater(context, res, resolve, reject);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    });
                },
                deleteChapter(context, { chapter, article_id }) {
                    context.commit('startLoading');
                    const ep = '/v2/articles/' + article_id + '/chapters/' + chapter.id;
                    return new Promise((resolve, reject) => {
                        http.api.client.delete(ep, {
                            headers: {
                                'Accept-language': chapter[config.localeKey],
                            },
                        })
                            .then(res => {
                                rowUpdater(context, res, resolve, reject);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    });
                },
                saveChapter(context, { chapter, article_id }) {
                    context.commit('startLoading');
                    const payload = {
                        chapter: chapter,
                    };
                    const ep = '/v2/articles/' + article_id + '/chapters/' + chapter.id;
                    return new Promise((resolve, reject) => {
                        http.api.client.put(ep, JSON.stringify(payload), {
                            headers: {
                                'Accept-language': chapter[config.localeKey],
                            },
                        })
                            .then(res => {
                                rowUpdater(context, res, resolve, reject);
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    });
                },
                search(context, params) {
                    context.commit('clearSearch');
                    context.commit('startLoading');
                    const sendData = {
                        text: '',
                        limit: 8,
                        categories: [],
                        tags: [],
                        progress: [],
                    };
                    if (params.text) sendData.text = params.text;
                    if (params.limit) sendData.limit = params.limit;
                    //Complete this function to consider other params if needed
                    const ep = '/v1/articles/searches';
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, JSON.stringify(sendData))
                            .then(res => {
                                if (res && res.data && res.data[config.respEls]) {
                                    context.commit('setSearched', res.data[config.respEls]);
                                    resolve(res.data[config.respEls]);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    });
                },
            },
        };
    },
};
