import http from '../../../libs/http';
import tools from '../../../libs/tools';

const config = {
    ep: '/ms-1/paths/:path_id/teams/:team_id',
    reqEl: 'path',
    respEls: 'paths',
    respEl: 'path',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
};

const getUrl = (path_id, team_id, endpoint) => endpoint.replace(":path_id", path_id).replace(":team_id", team_id);

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                available: []
            },
            getters: {
                ...standardStore.getters,
                available: state => state.available
            },
            mutations: {
                ...standardStore.mutations,
                setAvailable: (state, values) => {
                    state.available = values;
                }
            },
            actions: {
                fetchAvailable(context, {company_id}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = '/ms-1/paths';
                    const params = {
                        company_id: company_id
                    }
                    tools.report('EP ', ep);
                    const cfg = {
                      ...config,
                        storeRows: 'setAvailable'
                    };
                    return http.api.listPromise(context, cfg, ep, params);
                },
                fetchList(context, {team_id}) {
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = '/ms-1/teams/:id/paths'.replace(":id", team_id);
                    tools.report('EP ', ep);
                    return http.api.listPromise(context, config, ep);
                },
                addPath(context, {team_id, path_id, ord}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getUrl(path_id, team_id, config.ep);
                    tools.report('EP ', ep);
                    const payload = ord ? {rank: ord} : null;

                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, JSON.stringify(payload))
                            .then(res => {
                                if (res && res['data'] && res['data']['done']) {
                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                savePath(context, {team_id, path_id, ord}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getUrl(path_id, team_id, config.ep);
                    tools.report('EP ', ep);
                    const payload = ord ? {rank: ord} : null;

                    return new Promise((resolve, reject) => {
                        http.api.client.patch(ep, JSON.stringify(payload))
                            .then(res => {
                                if (res && res['data'] && res['data']['done']) {
                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                deletePath(context, {team_id, path_id}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getUrl(path_id, team_id, config.ep);
                    tools.report('EP ', ep);

                    return new Promise((resolve, reject) => {
                        http.api.client.delete(ep)
                            .then(res => {
                                if (res && res['data'] && res['data']['done']) {
                                    resolve(true);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha compiuto l\'operazione richiesta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                }
            },
        };
    },
};
