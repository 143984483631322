const List = () => import('../../views/cont/books/List');
const Edit = () => import('../../views/cont/books/Edit');

export default {
    Create: {
        path: 'cont/books/new',
        name: 'cont_books_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: "" },
        meta: {
            basePath: '/app/cont/books',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Abstracts Libri', to: '/app/cont/books' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'cont/books/:id/:lang?',
        name: 'cont_books_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id,
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/books',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Libri', to: '/app/cont/books'},
                { label: 'Modifica Abstract' }],
        },
    },
    List: {
        path: 'cont/books',
        name: 'cont_books_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Abstracts Libri' }],
        },
    }
}
