import http from '../../../libs/http';
import tools from '../../../libs/tools';

const config = {
    ep: '/v1/companies/:id/teams',
    reqEl: 'company_team',
    respEls: 'company_teams',
    respEl: 'company_team',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
    methods: {
        savePlain: 'put',
    },
};

const getUrl = (company_id, endpoint) => endpoint.replace(":id", company_id);

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
            },
            getters: {
                ...standardStore.getters,
            },
            mutations: {
                ...standardStore.mutations,
            },
            actions: {
                createPlain: (context, data) => {
                    const ep = getUrl(data.company_id, config.ep);
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    let payload = {};
                    if (config.reqEl) {
                        payload[config.reqEl] = data;
                    } else {
                        payload = data;
                    }

                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, JSON.stringify(payload))
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEl]) {
                                    if (config.storeRow) {
                                        context.commit(config.storeRow, res['data'][config.respEl]);
                                    }
                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                savePlain: (context, data) => {
                    const ep = getUrl(data.company_id, config.ep) + '/' + data.id;
                    tools.report('EP ', ep);
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    let payload = {};
                    if (config.reqEl) {
                        payload[config.reqEl] = data;
                    } else {
                        payload = data;
                    }
                    const remoteCall = http.api.client.put;

                    return new Promise((resolve, reject) => {
                        remoteCall(ep, JSON.stringify(payload))
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEl]) {
                                    if (config.storeRow) {
                                        context.commit(config.storeRow, res['data'][config.respEl]);
                                    }
                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                fetchOne(context, {company_id, id}) {
                    tools.report('FETCHING ', id);
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getUrl(company_id, config.ep) + '/' + id;
                    tools.report('EP ', ep);

                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEl]) {
                                    if (config.storeRow) {
                                        context.commit(config.storeRow, res['data'][config.respEl]);
                                    }
                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    if (config.stopLoading) {
                                        context.commit(config.stopLoading);
                                    }
                                },
                            );
                    });
                },
                fetchList(context, company_id) {
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getUrl(company_id, config.ep);

                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEls]) {
                                    if (config.storeRows) {
                                        context.commit(config.storeRows, res['data'][config.respEls]);
                                    }
                                    resolve(res['data'][config.respEls]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },

            },
        };
    },
};
