const Edit = () => import ('../../views/web/apidoc/Edit');

export default {
    Update: {
        path: 'web/apidoc',
        name: 'web_apidoc', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/web/apidoc',
            breadcrumb: [
                { label: 'API: Documentazione per i client' },
                { label: 'Aggiorna' }],
        },
    },
}
