const List = () => import('../../views/users/company_users/List');

export default {
    List: {
        path: 'users/companies/:company_id/users',
        name: 'users_company_users_list',
        exact: true,
        props: (route) => {
            return {
                company_id: parseInt(route.params.company_id, 10),
                team_id: 0
            };
        },
        component: List,
        meta: {
            breadcrumb: [{ label: 'B2B' }, { label: 'Aziende', to: '/app/users/companies' }],
        },
    },
    ListPerTeam: {
        path: 'users/companies/:company_id/company_teams/:team_id/users',
        name: 'users_company_team_users_list',
        exact: true,
        props: (route) => {
            return {
                company_id: parseInt(route.params.company_id, 10),
                team_id: parseInt(route.params.team_id, 10),
            };
        },
        component: List,
        meta: {
            breadcrumb: [{ label: 'B2B' }, { label: 'Aziende', to: '/app/users/companies' }],
        },
    }
}
