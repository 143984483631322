import Subscriptions from './import_subscriptions/store';
import Purchases from './purchase_subscriptions/store';
import CompanySubscriptions from './import_company_subscriptions/store';
import GiftCoupons from './gift_coupons/store';

export default {
    init(standardStore){
        return {
            namespaced: true,
            state(){
                return {}
            },
            getters: {},
            mutations: {},
            actions: {},
            modules: {
                ImportSubscriptions: Subscriptions.init(standardStore),
                ImportPurchases: Purchases.init(standardStore),
                ImportCompanySubscriptions: CompanySubscriptions.init(standardStore),
                ImportGiftCoupons: GiftCoupons.init(standardStore),
            }
        }
    },

}
