import Offers from './offers/store';
import WebRedirects from './web_redirects/store';
import Domains from './domains/store';

export default {
    init(standardStore){
        return {
            namespaced: true,
            state(){
                return {}
            },
            getters: {},
            mutations: {},
            actions: {},
            modules: {
                Offers: Offers.init(standardStore),
                WebRedirects: WebRedirects.init(standardStore),
                DomainChecker: Domains.init()
            }
        }
    },

}
