const List = () => import('../../views/web/offers/List');
const Edit = () => import ('../../views/web/offers/Edit');

export default {
    Create: {
        path: 'web/offers/new',
        name: 'web_offers_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/web/offers',
            breadcrumb: [
                { label: 'FRONTEND MANAGEMENT' },
                { label: 'Offerte', to: '/app/web/offers' },
                { label: 'Nuova' }],
        },
    },
    Update: {
        path: 'web/offers/:id',
        name: 'web_offers_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: parseInt(route.params.id, 10)
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/web/offers',
            breadcrumb: [
                { label: 'FRONTEND MANAGEMENT' },
                { label: 'Offerte', to: '/app/web/offers'},
                { label: 'Modifica Offerta' }],
        },
    },
    List: {
        path: 'web/offers',
        name: 'web_offers_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'FRONTEND MANAGEMENT' }, { label: 'Offerte' }],
        },
    }
}
