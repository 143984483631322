const List = () => import('../../views/cont/lookups/tags/List');
const Edit = () => import ('../../views/cont/lookups/tags/Edit');

export default {
    Create: {
        path: 'cont/tags/new',
        name: 'cont_tags_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: "" },
        meta: {
            basePath: '/app/cont/tags',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Lookups' },
                { label: 'Tags', to: '/app/cont/tags' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'cont/tags/:id/:lang?',
        name: 'cont_tags_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id,
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/tags',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Lookups' },
                { label: 'Tags', to: '/app/cont/tags'},
                { label: 'Modifica Tag' }],
        },
    },
    List: {
        path: 'cont/tags',
        name: 'cont_tags_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Lookups' }, { label: 'Tags' }],
        },
    }
}
