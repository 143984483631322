import tools from '../../../libs/tools';
import http from '../../../libs/http';

const config = {
    ep: '/v1/users',
    reqEl: 'user',
    respEls: 'users',
    respEl: 'user',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
    methods: {
        savePlain: 'put',
    },
};
const userStatus = (data, now) => {
    if (data['subscription'] && !data['subscription']['expired']) {
        return "premium";
    }
    if (data['freemium']){
        data['freemium']['end'] = tools.time.api2Date(data['freemium']['To'])
        if ( data['freemium']['end'] > now) {
            return "freemium";
        }
    }
    if (data['subscription'] && data['subscription']['expired']) {
        return "expired";
    }
    return "free";
}
export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                intercomSyncing: 0,
                personifying: 0,
                subscriptionsXUser: {},
                savingSub: 0,
                movingSub: 0,
                events: []
            },
            getters: {
                ...standardStore.getters,
                savingSub: state => state.savingSub,
                intercomSyncing: state => state.intercomSyncing,
                personifying: state => state.personifying,
                userSubscriptions: state => id => {
                    return state.subscriptionsXUser[id] || [];
                },
                events: state => state.events,
                movingSub: state => state.movingSub,
            },
            mutations: {
                ...standardStore.mutations,
                setIntercomSyncing(state, value) {
                    state.intercomSyncing = value;
                },
                setSavingSub(state, value) {
                    state.savingSub = value;
                },
                setPersonifying(state, value) {
                    state.personifying = value;
                },
                setEvents(state, value){
                    state.events = value;
                },
                setUserSubscriptions(state, { id, values }) {
                    if (!values || values.length === 0) {
                        state.subscriptionsXUser[id] = null;
                    }
                    state.subscriptionsXUser[id] = values;
                },
                setMovingSub(state, value) {
                    state.movingSub = value;
                },
            },
            actions: {
                ...standardStore.actions(config),
                personify(context, id) {
                    tools.report('personify ', id);
                    context.commit('setPersonifying', id);
                    const ep = http.getUrl('v1/auth/personify') + '/' + id;
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then(res => {
                                if (res['data']) {
                                    resolve(res.data);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit('setPersonifying', 0);
                                },
                            );
                    });
                },
                syncIntercom(context, id) {
                    tools.report('syncIntercom ', id);

                    context.commit('setIntercomSyncing', id);
                    const ep = http.getUrl(config.ep) + '/' + id + '/intercom';
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then(() => {
                                resolve();
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit('setIntercomSyncing', 0);
                                },
                            );
                    });
                },
                removeCredentials(context, id) {
                    tools.report('removeCredentials ', id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/' + id + '/credentials';
                    return new Promise((resolve, reject) => {
                        http.api.client.delete(ep)
                            .then((res) => {
                                if (res['data']){
                                    resolve(res['data']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit(config.stopLoading);
                                },
                            );
                    });
                },
                getEvents(context, id) {
                    tools.report('getEvents ', id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/' + id + '/funnel_events';
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then((res) => {
                                if (res['data'] && res['data']['events']){
                                    context.commit('setEvents', res['data']['events']);
                                    resolve(res['data']['events']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit(config.stopLoading);
                                },
                            );
                    });
                },
                search(context, { page, limit, sortBy, order, id, last_name, email, ext_id }) {
                    page = page || 1;
                    limit = limit || 10;
                    sortBy = sortBy || 'email';
                    order = order || 1;
                    const payload = {
                        limit: limit,
                        order: order,
                        orderby: sortBy,
                        page: page,
                    };
                    if (id) {
                        payload['id'] = id;
                    }
                    if (email) {
                        payload['email'] = email;
                    }
                    if (last_name) {
                        payload['last_name'] = last_name;
                    }
                    if (ext_id) {
                        payload['subscription_external_id'] = ext_id;
                    }
                    tools.report('SEARCH USERS ', page, limit, sortBy);

                    context.commit('searchReset');
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep) + '/search';
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, payload)
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEls]) {
                                    const users = res['data'][config.respEls];
                                    if (res['data']['subscriptions']) {
                                        const now = new Date();
                                        users.forEach(user => {
                                            const uid = user['id'];
                                            if (res['data']['subscriptions'][uid]) {
                                                user['subscription'] = res['data']['subscriptions'][uid];
                                                const expiry = new Date(Date.parse(user['subscription']['expiry_at']));
                                                user['subscription']['expired'] = expiry < now;
                                                user['subscription']['provider'] = user['subscription']["plan_provider"]["name"].toUpperCase() || "PREMIUM";
                                                user['subscription']['provider'] += " exp " + expiry.toLocaleString();
                                            }
                                            if (res['data']['freemium'][uid]){
                                                user['freemium'] = res['data']['freemium'][uid];
                                            }
                                            user['status'] = userStatus(user, now)
                                        })
                                    }

                                    context.commit('setRows', res['data'][config.respEls]);
                                    if (res['data']['page'])
                                        context.commit('setPage', res['data']['page']);
                                    if (res['data']['pages'])
                                        context.commit('setPages', res['data']['pages']);

                                    resolve(res['data']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    if (config.stopLoading) {
                                        context.commit(config.stopLoading);
                                    }
                                },
                            );
                    });

                },
                getSubscriptionDetail(context, id) {
                    tools.report('getSubscriptionDetail ', id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl('/v1/subscriptions') + '/' + id + '/info';
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then(res => {
                                if (res['data']) {
                                    resolve(res.data);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit(config.stopLoading);
                                },
                            );
                    });
                },
                refreshAppstoreSubscription(context, id) {
                    tools.report('refreshAppstoreSubscription ', id);
                    context.commit(config.startLoading);
                    const ep = http.getUrl('/v1/subscriptions') + '/' + id + '/appstore/refresh';
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then(res => {
                                if (res['data']) {
                                    resolve(res.data);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit(config.stopLoading);
                                },
                            );
                    });
                },
                getUserData(context, id) {
                    tools.report('getUserData ', id);
                    context.commit(config.startLoading);
                    context.commit(config.storeRow, null);
                    context.commit('setUserSubscriptions', id, null);
                    const ep = http.getUrl(config.ep) + '/' + id + '/details';
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then(res => {
                                if (res['data']) {
                                    if (res.data.user) {
                                        if (res.data['freemium']){
                                            res.data.user['freemium'] = res['data']['freemium'];
                                        }
                                        context.commit(config.storeRow, res.data.user);
                                    }
                                    if (res.data.subscriptions) {
                                        context.commit('setUserSubscriptions', { id, values: res.data.subscriptions });
                                    }
                                    resolve(res.data);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit(config.stopLoading);
                                },
                            );
                    });
                },
                syncIntercomSub(context, sub_id) {
                    tools.report('syncIntercom ', sub_id);

                    context.commit('setIntercomSyncing', sub_id);
                    const ep = http.getUrl('v1/subscriptions') + '/' + sub_id + '/events';
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, {})
                            .then(() => {
                                resolve();
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit('setIntercomSyncing', 0);
                                },
                            );
                    });
                },
                updateUserSubscription(context, sub) {
                    tools.report('updateUserSubscription ', sub.id);

                    context.commit('setSavingSub', sub.id);
                    const ep = http.getUrl('v1/subscriptions') + '/' + sub.id;
                    return new Promise((resolve, reject) => {
                        http.api.client.put(ep, { subscription: sub })
                            .then(res => {
                                if (res['data']) {
                                    if (res.data.subscriptions) {
                                        context.commit('setUserSubscriptions', {
                                            id: sub.user_id,
                                            values: res.data.subscriptions,
                                        });
                                    }
                                    resolve(res.data);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit('setSavingSub', 0);
                                },
                            );
                    });
                },
                moveSubscription(context, { sub, to }) {
                    tools.report('moveSubscription ', sub.id, sub.user_id, to);
                    context.commit('setMovingSub', sub.id);
                    const ep = http.getUrl('v1/subscriptions') + '/' + sub.id + '/move';
                    const payload = {
                        from: sub.user_id,
                        to: to,
                    };
                    return new Promise((resolve, reject) => {
                        http.api.client.put(ep, payload)
                            .then(res => {
                                if (res['data']) {
                                    if (res.data.done) {
                                        //Remove from the user
                                        const old = context.getters.userSubscriptions(sub.user_id).filter(s => s.id !== sub.id);
                                        context.commit('setUserSubscriptions', { id: sub.user_id, values: old });
                                        //Attrib to the receiver
                                        const receiver = context.getters.userSubscriptions(sub.to);
                                        receiver.push(sub);
                                        context.commit('setUserSubscriptions', { id: to, values: receiver });
                                    }
                                    resolve(res.data);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit('setMovingSub', 0);
                                },
                            );
                    });
                },
            },
        };
    },
};
