import http from '../../../libs/http';

const config = {
    ep: '/v1/companies',
    reqEl: 'company',
    respEls: 'companies',
    respEl: 'company',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
    methods: {
        savePlain: 'put',
    },
};

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
            },
            getters: {
                ...standardStore.getters,
            },
            mutations: {
                ...standardStore.mutations,
            },
            actions: {
                ...standardStore.actions(config),
                downloadPinnedSkillsReport(context, companyId) {
                    const ep = '/v1/companies/:id/reports/featured_skills_xps'.replace(':id', companyId);
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep, { responseType: 'blob' }).then(response => {
                            resolve(response['data']);
                        }).catch(err => {
                            reject(http.formatError('server-error', err));
                        });
                    });
                },
            },
        };
    },
};
