const View = () => import('../../views/stats/signups/live/View');

export default {
    View: {
        path: 'stats/signups/live',
        name: 'stats_signups_live',
        exact: true,
        component: View,
        meta: {
            breadcrumb: [{ label: 'STATISTICHE' }, { label: 'Signups' }],
        },
    }
}
