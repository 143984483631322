import http from '../../../libs/http';

const config = {
    ep: '/v1/stats/live/active_users',
    respEls: 'stats',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
};

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: () => {
                return {
                    ...standardStore.state,
                    keepRecordsForMin: 15,
                    activeUsers: [],
                    endpointHits: [],
                    visitorsHits: [],
                    lastVisitorsHit: 0,
                    lastActiveUsers: 0,
                    top5Users: [],
                };
            },
            getters: {
                ...standardStore.getters,
                activeUsers: state => state.activeUsers,
                lastVisitorsHit: state => state.lastVisitorsHit,
                lastActiveUsers: state => state.lastActiveUsers,
                keepRecordsForMin: state => state.keepRecordsForMin,
                endpointHits: state => state.endpointHits,
                top5Users: state => state.top5Users,
                hitEndpoints: state => {
                    const track = {};
                    state.endpointHits.forEach(hit => {
                        Object.keys(hit.data).forEach(key => {
                            track[key] = true;
                        });
                    });
                    return Object.keys(track);
                },
                getEndpointHits: state => (ep) => {
                    const data = [];
                    state.endpointHits.forEach(hit => {
                        let val = 0;
                        if (hit.data[ep]) {
                            val = hit.data[ep];
                        }
                        data.push({
                            ts: hit.ts,
                            val: val,
                        });
                    });
                    return data;
                },
                visitorsHits: state => state.visitorsHits,
            },
            mutations: {
                ...standardStore.mutations,
                setKeepRecordsForMin(state, value) {
                    state.keepRecordsForMin = value;
                },
                setTop5Users(state, values) {
                    state.top5Users = values;
                },
                addActiveUsers(state, data) {
                    const v = Object.keys(data.values).length || 0;
                    state.activeUsers.push({
                        ts: data.ts,
                        users: v,
                    });
                    state.lastActiveUsers = v;
                    if (state.activeUsers.length > state.keepRecordsForMin) {
                        state.activeUsers.shift();
                    }
                },
                resetActiveUser(state) {
                    state.activeUsers = [];
                    state.lastActiveUsers = 0;
                },
                resetEndpointHits(state) {
                    state.endpointHits = [];
                },
                addEndpointHits(state, data) {
                    state.endpointHits.push({
                        ts: data.ts,
                        data: data.values,
                    });
                    if (state.endpointHits.length > state.keepRecordsForMin) {
                        state.endpointHits.shift();
                    }
                },
                resetVisitorsHits(state) {
                    state.visitorsHits = [];
                    state.lastVisitorsHit = 0;
                },
                addVisitorHits(state, data) {
                    state.visitorsHits.push({
                        ts: data.ts,
                        value: data.value,
                    });
                    state.lastVisitorsHit = data.value;
                    if (state.visitorsHits.length > state.keepRecordsForMin) {
                        state.visitorsHits.shift();
                    }
                },
            },
            actions: {
                ...standardStore.actions(config),
                fetchData(context) {
                    const now = new Date();
                    const ep = http.getUrl(config.ep);
                    context.commit(config.startLoading);
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, {})
                            .then(res => {
                                if (res['data'] && res['data']['stats']) {
                                    context.commit('addActiveUsers', {
                                        ts: now,
                                        values: res['data']['stats']['active_users'] || {}
                                    });
                                    context.commit('addEndpointHits', {
                                        ts: now,
                                        values: res['data']['stats']['endpoints'] || {}
                                    });
                                    context.commit('addVisitorHits', {
                                        ts: now,
                                        value: res['data']['stats']['visitors_hits'] || 0
                                    })
                                    context.commit('setTop5Users', res['data']['stats']['top5_users'] || [])
                                    resolve(res['data']['stats']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit(config.stopLoading);
                                },
                            );
                    });
                },
            },
        };
    },
};
