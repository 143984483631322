import http from '../../../libs/http';
import tools from '../../../libs/tools';

const config = {
    ep: '/v1/companies/:id/teams/:tid/skills',
    reqEl: 'skill',
    respEls: 'skills',
    respEl: 'skill',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
};

const getUrl = (company_id, team_id, endpoint) => endpoint.replace(":id", company_id).replace(":tid", team_id);

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
            },
            getters: {
                ...standardStore.getters,
            },
            mutations: {
                ...standardStore.mutations,
            },
            actions: {
                fetchList(context, {company_id, team_id}) {
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getUrl(company_id, team_id, config.ep);
                    tools.report('EP ', ep);
                    return http.api.listPromise(context, config, ep);
                },
                addSkill(context, {company_id, team_id, skill_id, ord}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getUrl(company_id, team_id, config.ep) + '/' + skill_id;
                    tools.report('EP ', ep);
                    const payload = {skill: {ord: ord}};

                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, JSON.stringify(payload))
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEl]) {
                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                saveSkill(context, {company_id, team_id, skill_id, ord}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getUrl(company_id, team_id, config.ep) + '/' + skill_id;
                    tools.report('EP ', ep);
                    const payload = {skill: {ord: ord}};

                    return new Promise((resolve, reject) => {
                        http.api.client.put(ep, JSON.stringify(payload))
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEl]) {
                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                deleteSkill(context, {company_id, team_id, skill_id}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getUrl(company_id, team_id, config.ep) + '/' + skill_id;
                    tools.report('EP ', ep);

                    return new Promise((resolve, reject) => {
                        http.api.client.delete(ep)
                            .then(res => {
                                if (res && res['data'] && res['data']['done']) {
                                    resolve(true);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha compiuto l\'operazione richiesta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                }

            },
        };
    },
};
