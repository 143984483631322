import MacroCategories from './macro_categories/store';
import Posts from './posts/store';

export default {
    init(standardStore){
        return {
            namespaced: true,
            state(){
                return {}
            },
            getters: {},
            mutations: {},
            actions: {},
            modules: {
                MacroCategories: MacroCategories.init(standardStore),
                Posts: Posts.init(standardStore)
            }
        }
    },

}
