const List = () => import('../../views/web/web_redirects/List');
const Edit = () => import('../../views/web/web_redirects/Edit');

export default {
    Create: {
        path: 'web/web_redirects/new',
        name: 'web', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/web/web_redirects',
            breadcrumb: [
                { label: 'WEB' },
                { label: 'Redirects sul sito', to: '/app/web/web_redirects' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'web/web_redirects/:id',
        name: 'web_web_redirects_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: parseInt(route.params.id, 10)
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/web/web_redirects',
            breadcrumb: [
                { label: 'WEB' },
                { label: 'Redirects sul sito', to: '/app/web/web_redirects'},
                { label: 'Inserisci Dati richiesti' }],
        },
    },
    List: {
        path: 'web/web_redirects',
        name: 'web_web_redirects_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'WEB' }, { label: 'Redirects sul sito' }],
        },
    }
}
