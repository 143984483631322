import http from '../../../libs/http';

const config = {
    ep: 'v1/quizzes',
    reqEl: 'quiz',
    respEls: 'quiz',
    respEl: 'quiz',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
};

const qOperations = {
  Save: "SAVE",
  Create: "CREATE",
  Delete: "DELETE"
};

function SaveQuestionCMS(context, question, method){
    context.commit(config.startLoading);
    const payload = {
        question: question
    }
    let ep = config.ep + '/' + question['quiz_id'] + '/questions';
    if (question['id']){
        ep += '/' + question['id'];
    }
    return SaveSubpartCMS(context, ep, payload, method);
}
function SaveAnswerCMS(context, answer, method){
    context.commit(config.startLoading);
    const payload = {
        answer: answer
    }
    let ep = config.ep + '/' + answer['quiz_id'] + '/questions' + '/' + answer['question_id'] + '/answers';
    if (answer['id']){
        ep += '/' + answer['id'];
    }
    return SaveSubpartCMS(context, ep, payload, method);
}

function SaveSubpartCMS(context, ep, payload, method){
    context.commit(config.startLoading);

    let apiCall;
    switch (method){
        case qOperations.Save:
            apiCall = http.api.client.put(ep, payload);
            break;
        case qOperations.Create:
            apiCall = http.api.client.post(ep, payload);
            break;
        case qOperations.Delete:
            apiCall = http.api.client.delete(ep);
            break;
        default:
            apiCall = http.api.client.post(ep, payload);
    }
    return new Promise((resolve, reject) => {
        apiCall
            .then(res => {
                if (res && res.data && res.data[config.respEl]){
                    context.commit(config.storeRow, res.data[config.respEls]);
                    resolve(res.data[config.respEl]);
                    return;
                }
                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
            })
            .catch(err => {
                reject(http.formatError('server-error', err));
            })
            .finally(() => {
                context.commit(config.stopLoading);
            });
    });
}

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                quizzes: {}
            },
            getters: {
                ...standardStore.getters,
                quizzes: state => state.quizzes,
            },
            mutations: {
                ...standardStore.mutations,
                setQuizzes(state, values){
                    const lines = {};
                    if (values && values.length > 0){
                        values.forEach(row => {
                            if (!lines[row['book_id']]) lines[row['book_id']] = [];
                            lines[row['book_id']].push(row);
                        });
                    }
                    state.quizzes = lines;
                },
                addQuiz(state, value){
                    const values = { ...state.quizzes };
                    if (!values[value['book_id']]) values[value['book_id']] = [];
                    values[value['book_id']].push(value);
                    state.quizzes = values;
                }
            },
            actions: {
                ...standardStore.actions(config),
                DeleteQuiz(context, id){
                    const ep = config.ep + '/' + id;
                    context.commit(config.startLoading);
                    return new Promise((resolve, reject) => {
                        http.api.client.delete(ep)
                            .then((res) => {
                                if (res && res['data'] && res['data']['done']) {
                                    resolve(true);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            }).finally(() => {
                            context.commit(config.stopLoading);
                        });

                    })
                },
                ToggleQuizActivation(context, id){
                    const ep = config.ep + '/' + id + '/activation';
                    context.commit(config.startLoading);
                    return new Promise((resolve, reject) => {
                        http.api.client.put(ep, {})
                            .then((res) => {
                                if (res && res['data'] && res['data'][config.respEl]) {
                                    //Change model in row
                                    context.commit(config.storeRow, res['data'][config.respEl])
                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            }).finally(() => {
                            context.commit(config.stopLoading);
                        });

                    })
                },
                SaveQuestion(context, question){
                    return SaveQuestionCMS(context, question, qOperations.Save);
                },
                CreateQuestion(context, question){
                    return SaveQuestionCMS(context, question, qOperations.Create);
                },
                DeleteQuestion(context, question){
                    return SaveQuestionCMS(context, question, qOperations.Delete);
                },
                SaveAnswer(context, data){
                    return SaveAnswerCMS(context, data, qOperations.Save);
                },
                CreateAnswer(context, data){
                    return SaveAnswerCMS(context, data, qOperations.Create);
                },
                DeleteAnswer(context, data){
                    return SaveAnswerCMS(context, data, qOperations.Delete);
                },
                ListAll(context, lang) {
                    context.commit(config.startLoading);

                    if (lang === '') {
                        lang = 'it';
                    }
                    const options = {
                        headers: {
                            'Accept-language': lang,
                        },
                    };
                    return new Promise((resolve, reject) => {
                        http.api.client.get(config.ep, options)
                            .then(res => {
                                if (res && res.data && res.data[config.respEls]){
                                    context.commit('setQuizzes', res.data[config.respEls]);
                                    resolve(res.data[config.respEls]);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit(config.stopLoading);
                            });
                    });
                },
            },
        };
    },
};
