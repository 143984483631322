import http from '../../../libs/http';

const config = {
    ep: "/v1/chatbots/knowledge",
    reqEl: "knowledge",
    respEls: "contents",
    respEl: "knowledge",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale",
    botId: "helpdesk",
    methods: {
        savePlain: 'put'
    }
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: { ...standardStore.actions },
            mutations: {...standardStore.mutations },
            getters: {...standardStore.getters },
            actions: {
                ...standardStore.actions(config),
                search(context, params){
                    context.commit('startLoading');
                    context.commit(config.storeRows, []);
                    const payload = {
                        bot_id: config.botId
                    };
                    if (params['lang']) {
                        payload['lang'] = params.lang;
                    }
                    return new Promise((resolve, reject) => {
                        http.api.client.post(config.ep + "/searches", JSON.stringify(payload))
                            .then(res => {
                                if (res && res.data && res.data[config.respEls]){
                                    context.commit(config.storeRows, res.data[config.respEls]);
                                    resolve(res.data[config.respEls]);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    })
                }
            }
        }
    }
}
