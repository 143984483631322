const List = () => import('../../views/cont/lookups/publishers/List');
const Edit = () => import ('../../views/cont/lookups/publishers/Edit');

export default {
    Create: {
        path: 'cont/publishers/new',
        name: 'cont_publishers_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: "" },
        meta: {
            basePath: '/app/cont/publishers',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Lookups' },
                { label: 'Publishers', to: '/app/cont/publishers' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'cont/publishers/:id',
        name: 'cont_publishers_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/publishers',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Lookups' },
                { label: 'Publishers', to: '/app/cont/publishers'},
                { label: 'Modifica Publisher' }],
        },
    },
    List: {
        path: 'cont/publishers',
        name: 'cont_publishers_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Lookups' }, { label: 'Publishers' }],
        },
    }
}
