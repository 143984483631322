import tools from '../../../libs/tools';
import http from '../../../libs/http';

const config = {
    ep: '/v1/companies/:company_id/cms_users',
    reqEl: 'user',
    respEls: 'users',
    respEl: 'user',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
    methods: {
        savePlain: 'put',
    },
};

const getUrl = company_id => config.ep.replace(":company_id", company_id);

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
            },
            getters: {
                ...standardStore.getters,
            },
            mutations: {
                ...standardStore.mutations,
            },
            actions: {
                fetchList(context, { company_id }){
                    const configCopy = {
                        ...config,
                        ep: getUrl(company_id)
                    };
                    return standardStore.actions(configCopy).fetchList(context);
                },
                grantAccess(context, {company_id, id, role_id}){
                    tools.report('Grant access to ', id);
                    const ep = getUrl(company_id);
                    const payload = {
                        role_id: role_id,
                        company_id: company_id,
                        user_id: id
                    }
                    return http.api.boolDonePromise(context, config, ep, payload);
                },
                changeRole(context, {company_id, id, role_id}){
                    tools.report('Patch Role ', id);
                    const ep = getUrl(company_id) + '/' + id;
                    const payload = {
                        role_id: role_id
                    }
                    return http.api.boolPatchDonePromise(context, config, ep, payload);
                },
                delete(context, {company_id, id}) {
                    tools.report('Delete ', id);
                    context.commit('startLoading');
                    const ep = getUrl(company_id) + '/' + id;
                    return http.api.boolSimpleDeletePromise(context, config, ep);
                },
            },
        };
    },
};
