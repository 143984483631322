import http from '../../../libs/http';

const config = {
    ep: "v1/psycho/psychologists/:id/patients_history",
    ep_t: "v1/psycho/psychologists/:id/patient_interviews",
    ep_cal: "v1/psycho/psychologists/:id/calendly_events",
    ep_psycho: "v1/psycho/psychologists/searches",
    ep_stats: "v1/psycho/stats/summary",
    reqEl: "",
    respEls: "history",
    respEl: "history",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale"
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                psycho: {},
                stats: []
            },
            getters: {
                ...standardStore.getters,
                psycho: state => state.psycho,
                stats: state => state.stats
            },
            mutations: {
                ...standardStore.mutations,
                setPsycho(state, payload){
                    state.psycho = payload;
                },
                setStats(state, value){
                    state.stats = value;
                }
            },
            actions: {
                ...standardStore.actions(config),
                fetchStats(context, {fromDate, toDate}){
                    const payload = {};
                    if (fromDate) {
                        payload["from_date"] = fromDate;
                    }
                    if (toDate){
                        payload["to_date"] = toDate;
                    }
                    const ep = config['ep_stats'];
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }

                    return new Promise((resolve, reject) => {
                        context.commit('setStats', []);
                        http.api.client.post(ep, payload)
                            .then(res => {
                                if (res && res['data'] && res['data']['stats']) {
                                    context.commit('setStats', res['data']['stats']);
                                    resolve(res['data']['stats']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    })
                },
                fetchPsycho(context, {email}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }

                    const ep = config['ep_psycho'];
                    const payload = {
                        email: email
                    }

                    return new Promise((resolve, reject) => {
                        if (config.storeRows) {
                            context.commit(config.storeRows, []);
                        }
                        http.api.client.post(ep, payload)
                            .then(res => {
                                if (res && res['data'] && res['data']['psycho']) {
                                    if (config.storeRows) {
                                        context.commit('setPsycho', res['data']['psycho']);
                                    }
                                    resolve(res['data'][config.respEls]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                fetchPatientList(context, {id, email}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }

                    const ep = config['ep'].replace(":id", id) ;
                    const payload = {
                        email: email
                    }

                    return new Promise((resolve, reject) => {
                        if (config.storeRows) {
                            context.commit(config.storeRows, []);
                        }
                        http.api.client.post(ep, payload)
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEls]) {
                                    if (config.storeRows) {
                                        context.commit(config.storeRows, res['data'][config.respEls]);
                                    }
                                    resolve(res['data'][config.respEls]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                fetchCalendlyEvents(context, {id}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }

                    const ep = config['ep_cal'].replace(":id", id) ;


                    return new Promise((resolve, reject) => {
                        if (config.storeRows) {
                            context.commit(config.storeRows, []);
                        }
                        http.api.client.get(ep)
                            .then(res => {
                                if (res && res['data'] && res['data']["events"]) {
                                    resolve(res['data']["events"]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },
                fetchPatientAnswers(context, {id, email, patient_id}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }

                    const ep = config['ep_t'].replace(":id", id) ;
                    const payload = {
                        email: email,
                        user_id: patient_id
                    }

                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, payload)
                            .then(res => {
                                if (res && res['data'] && res['data']['answers']) {
                                    resolve(res['data']['answers']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                }
            }
        }
    }
}
