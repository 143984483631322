const List = () => import('../../views/cont/lookups/copywriters/List');
const Edit = () => import ('../../views/cont/lookups/copywriters/Edit');

export default {
    Create: {
        path: 'cont/copywriters/new',
        name: 'cont_copywriters_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/cont/copywriters',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Lookups' },
                { label: 'Copywriters', to: '/app/cont/copywriters' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'cont/copywriters/:id',
        name: 'cont_copywriters_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: parseInt(route.params.id, 10)
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/copywriters',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Lookups' },
                { label: 'Copywriters', to: '/app/cont/copywriters'},
                { label: 'Modifica Copywriter' }],
        },
    },
    List: {
        path: 'cont/copywriters',
        name: 'cont_copywriters_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Lookups' }, { label: 'Copywriters' }],
        },
    }
}
