const List = () => import('../../views/cont/lookups/speakers/List');
const Edit = () => import ('../../views/cont/lookups/speakers/Edit');

export default {
    Create: {
        path: 'cont/speakers/new',
        name: 'cont_speakers_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/cont/speakers',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Lookups' },
                { label: 'Speakers', to: '/app/cont/speakers' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'cont/speakers/:id',
        name: 'cont_speakers_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: parseInt(route.params.id, 10)
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/speakers',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Lookups' },
                { label: 'Speakers', to: '/app/cont/speakers'},
                { label: 'Modifica Speaker' }],
        },
    },
    List: {
        path: 'cont/speakers',
        name: 'cont_speakers_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Lookups' }, { label: 'Speakers' }],
        },
    }
}
