import http from '../../../libs/http';

const config = {
    ep: '/v1/companies/:id/users',
    reqEl: 'user',
    respEls: 'users',
    respEl: 'users',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
    methods: {
        savePlain: 'put',
    },
};

const getUrl = (endpoint, company_id, team_id = 0) => {
    let ep = endpoint.replace(':id', company_id);
    if (team_id > 0) {
        ep = ep.replace('/users', '/teams/:tid/users').replace(':tid', team_id);
    }
    return ep;
};
const getSubscriptionUrl = (endpoint, company_id = 0) => {
    return endpoint.replace(':id', company_id).replace('/users', '/subscriptions');
};

const getChangeTeamUrl = (endpoint, company_id, user_id) => {
    return endpoint.replace(':id', company_id) + "/" + user_id + "/team";
};

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
            },
            getters: {
                ...standardStore.getters,
            },
            mutations: {
                ...standardStore.mutations,
            },
            actions: {
                ...standardStore.actions,
                changeUserTeam(context, { company_id, new_team_id, user_id }){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getChangeTeamUrl(config.ep, company_id, user_id);
                    const payload = {
                        team_id: new_team_id
                    }
                    return http.api.boolPatchDonePromise(context, config, ep, payload);
                },
                deleteCompanySubscription(context, {company_id, subscription_id}){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getSubscriptionUrl(config.ep, company_id) + "/" + subscription_id;
                    return http.api.boolSimpleDeletePromise(context, config, ep);
                },
                saveNewUser(context, editData) {
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = getSubscriptionUrl(config.ep, editData.company_id);
                    const payload = {
                        team_id: editData.team_id,
                        user_id: editData.id,
                        locale: editData.lang,
                        email_marketing: editData.email_marketing,
                        plan_id: editData.plan_id,
                        duration: parseInt(editData.duration, 10)
                    };
                    return http.api.boolDonePromise(context, config, ep, payload);
                },
                fetchList(context, { company_id, team_id }) {
                    if (config.storeRows) {
                        context.commit(config.storeRows, []);
                    }
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    if (!team_id) team_id = 0;
                    const ep = getUrl(config.ep, company_id, team_id);

                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEls]) {
                                    if (config.storeRows) {
                                        context.commit(config.storeRows, res['data'][config.respEls]);
                                    }
                                    resolve(res['data'][config.respEls]);
                                } else {
                                    resolve([]);
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                },

            },
        };
    },
};
