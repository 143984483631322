//import http from '../../libs/http';

export default {
    namespaced: true,
    state(){
        return {
            languages: {
                it: {
                    label: 'Italiano'
                },
                es: {
                    label: 'Spagnolo'
                },
                en: {
                    label: 'Inglese'
                },
            },
            publish_states: [
                {label: "Bozza", code: "draft", color: "danger"},
                {label: "Pronto per la pubblicazione", code: "ready", color: "warning"},
                {label: "Pubblicato", code: "published", color: "success"},
            ],
            extraBreadcrumbs: []
        }
    },
    getters: {
        languages: state => state.languages,
        publish_states: state => state.publish_states,
        extraBreadcrumbs: state => state.extraBreadcrumbs,
        publish_state: state => code => {
            let found = { label: "unknown" };
            if (!code) return found;
            state.publish_states.forEach(s => {
                if (s.code === code) {
                    found = s;
                }
            });
            return found;
        }
    },
    mutations: {
        resetExtraBreadcrumbs(state){
            state.extraBreadcrumbs = [];
        },
        setExtraBreadcrumbs(state, values){
            state.extraBreadcrumbs = values
        },
    },
    actions: {}
}
