const List = () => import('../../views/tasks/import_gift_coupons/List');
const Edit = () => import ('../../views/tasks/import_gift_coupons/Edit');

export default {
    Create: {
        path: 'tasks/give_gift_coupons/new',
        name: 'tasks_give_gift_coupons_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/tasks/give_gift_coupons',
            breadcrumb: [
                { label: 'TASKS' },
                { label: 'Importazione Utenti con Coupon Free', to: '/app/tasks/give_gift_coupons' },
                { label: 'Nuova' }],
        },
    },
    List: {
        path: 'tasks/give_gift_coupons',
        name: 'tasks_give_gift_coupons_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'TASKS' }, { label: 'Importazione utenti con Coupon Free' },],
        },
    }
}
