import Authn from './authn/store';
import Dashboard from './dashboard/store';
import Common from './common/store';
import Magazine from './magazine/store';
import Cont from './cont/store';
import Subscriptions from './subscriptions/store';
import Web from './web/store';
import Tasks from './tasks/store';
import Users from './users/store';
import Analytics from './analytics/store';
import Stats from './stats/store';
import Psycho from './psycho/store';
import Chatbots from './chatbots/store';

import { createStore } from 'vuex';
import http from '../libs/http';

const moduleStandardStore = {
    namespaced: true,
    state(){
        return {
            loading: false,
            rows: [],
            row: null,
            dirtyForm: false,
            page: 1,
            pages: 0
        }
    },
    getters: {
        loading: state => state.loading,
        rows: state => state.rows,
        row: state => state.row,
        dirtyForm: state => state.dirtyForm,
        page: state => state.page,
        pages: state => state.pages,
    },
    mutations: {
        setPage(state, value){
            state.page = value;
        },
        setPages(state, value){
            state.pages = value;
        },
        searchReset(state){
            state.page = 1;
            state.pages = 0;
            state.rows = [];
        },
        formIsDirty(state){
            state.dirtyForm = true;
        },
        formIsClean(state){
            state.dirtyForm = false;
        },
        startLoading(state){
            state.loading = true;
        },
        stopLoading(state){
            state.loading = false;
        },
        rowsChange(state, newRow){
            if (!newRow) return;
            if (!newRow.id) return;

            state.rows = state.rows.map(r => {
                if (r.id === newRow.id) {
                    return newRow;
                }
                return r;
            });
        },
        setRows(state, values){
            if (values && values.length){
                state.rows = values;
            } else {
                state.rows = [];
            }
        },
        appendRow(state, value){
            if (value){
                state.rows.push(value);
            }
        },
        prependRow(state, value){
            if (value){
                state.rows.unshift(value);
            }
        },
        setRow(state, value){
            if (value) {
                state.row = value;
            } else {
                state.row = null;
            }
        },
        setPlain(state, plain){
            Object.keys(state.row).forEach(key => {
                if (key === "translation") return;
                if (key === "translations") return;
                state.row[key] = plain[key];
            });
        },
        setTranslation(state, lang, translation){
            if (!state.row['translations']) state.row['translations'] = {};
            state.row['translations'][lang] = translation;
        },
        resetRow(state){
            state.row = null;
        }
    },
    actions(config){
        return {
            createPlain(context, data){
                return http.api.createPlain(context, config, data);
            },
            savePlain(context, data){
                return http.api.savePlain(context, config, data);
            },
            saveTranslation(context, { data, parent_id }){
                return http.api.saveTranslation(context, config, data, parent_id);
            },
            fetchOne(context, id) {
                return http.api.fetchOne(context, config, id);
            },
            fetchList(context, params){
                return http.api.fetchList(context, config, params);
            }
        }
    }
};

export default createStore({
    state(){
        return {};
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        Authn,
        Dashboard,
        Common,
        Magazine: Magazine.init(moduleStandardStore),
        Cont: Cont.init(moduleStandardStore),
        Subscriptions: Subscriptions.init(moduleStandardStore),
        Web: Web.init(moduleStandardStore),
        Tasks: Tasks.init(moduleStandardStore),
        Users: Users.init(moduleStandardStore),
        Analytics: Analytics.init(moduleStandardStore),
        Stats: Stats.init(moduleStandardStore),
        Psycho: Psycho.init(moduleStandardStore),
        Chatbots: Chatbots.init(moduleStandardStore)
    }
});

