const List = () => import('../../views/cont/complete_books/List');
const Edit = () => import('../../views/cont/complete_books/Edit');

export default {
    Create: {
        path: 'cont/complete_books/new',
        name: 'cont_complete_books_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: "" },
        meta: {
            basePath: '/app/cont/complete_books',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Libri Completi', to: '/app/cont/complete_books' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'cont/complete_books/:id/:lang?',
        name: 'cont_complete_books_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id,
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/complete_books',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Libri completi', to: '/app/cont/complete_books'},
                { label: 'Modifica Libro' }],
        },
    },
    List: {
        path: 'cont/complete_books',
        name: 'cont_complete_books_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Libri completi' }],
        },
    }
}
