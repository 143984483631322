const List = () => import('../../views/psycho/psychologists/List');
const PatientsList = () => import('../../views/psycho/patients/List');
const MyPatientsList = () => import('../../views/psycho/my-patients/List');
const PsychoSummaryStats = () => import('../../views/psycho/psychologists/Stats');

export default {
    List: {
        path: 'psycho/psychologists',
        name: 'psycho_psycho_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'PSICOLOGI' }, { label: 'Elenco Psicologi' }],
            basePath: '/app/psycho/psychologists'
        },
    },
    SummaryStats: {
        path: 'psycho/appointments-stats',
        name: 'psycho_app_stats_list',
        exact: true,
        component: PsychoSummaryStats,
        meta: {
            breadcrumb: [{ label: 'PSICOLOGI' }, { label: 'Statistiche appuntamenti' }],
            basePath: '/app/psycho/appointments-stats'
        },
    },
    MyPatientsList: {
        path: 'psycho/patients',
        name: 'psycho_psychologist_plist', //CHANGE
        exact: true,
        component: MyPatientsList,
        meta: {
            basePath: 'psycho/patients',
            breadcrumb: [
                { label: 'PSICOLOGI' },
            ],
        },
    },
    PatientsList: {
        path: 'psycho/psychologists/:id/patients',
        name: 'psycho_psychologists_plist', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: parseInt(route.params.id),
            };
        },
        component: PatientsList,
        meta: {
            basePath: '/app/psycho/psychologists',
            breadcrumb: [
                { label: 'PSICOLOGI' },
                { label: 'Elenco Psicologi', to: '/app/psycho/psychologists' }
            ],
        },
    },
}
