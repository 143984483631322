const List = () => import('../../views/cont/b2b_paths/List');
const Edit = () => import('../../views/cont/b2b_paths/Edit');

export default {
    Create: {
        path: 'users/companies/:company_id/paths/new',
        name: 'users_company_paths_new', //CHANGE
        exact: true,
        component: Edit,
        props: (route) => {
            return {
                company_id: parseInt(route.params.company_id, 10),
                id: 0
            };
        },
        meta: {
            basePath: '/app/users/companies/:company_id/paths',
            breadcrumb: [
                { label: 'B2B' },
                { label: 'Aziende', to: '/app/users/companies'}
            ],
        },
    },
    Update: {
        path: 'users/companies/:company_id/paths/:id/:lang?',
        name: 'users_company_paths_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: parseInt(route.params.id, 10),
                company_id: parseInt(route.params.company_id, 10),
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/users/companies',
            breadcrumb: [
                { label: 'B2B' },
                { label: 'Aziende', to: '/app/users/companies'}
            ],
        },
    },
    List: {
        path: 'users/companies/:company_id/paths',
        name: 'users_company_paths_list',
        exact: true,
        props: (route) => {
            return {
                company_id: parseInt(route.params.company_id, 10)
            };
        },
        component: List,
        meta: {
            breadcrumb: [{ label: 'B2B' }, { label: 'Aziende', to: '/app/users/companies' }],
        },
    }
}
