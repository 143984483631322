import Signups from './signups/store';
import CheckoutStarted from './checkout_started/store';
import CheckoutCompleted from './checkout_completed/store';
import Subscriptions from './subscriptions/store';
import ApiLive from './api/store';

export default {
    init(standardStore){
        return {
            namespaced: true,
            state(){
                return {}
            },
            getters: {},
            mutations: {},
            actions: {},
            modules: {
                Signups: Signups.init(standardStore),
                CheckoutStarted: CheckoutStarted.init(standardStore),
                CheckoutCompleted: CheckoutCompleted.init(standardStore),
                Subscriptions: Subscriptions.init(standardStore),
                Api: ApiLive.init(standardStore),
            }
        }
    },

}
