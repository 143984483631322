const View = () => import('../../views/stats/checkouts/live/View');

export default {
    View: {
        path: 'stats/checkouts/live',
        name: 'stats_checkouts_live',
        exact: true,
        component: View,
        meta: {
            breadcrumb: [{ label: 'STATISTICHE' }, { label: 'Checkouts' }],
        },
    }
}
