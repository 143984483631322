import tools from '../../../libs/tools';
import http from '../../../libs/http';

const config = {
    ep: "/v1/offers",
    reqEl: "offer",
    respEls: "offers",
    respEl: "offer",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale",
    methods: {
        savePlain: 'put'
    }
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: standardStore.state,
            getters: standardStore.getters,
            mutations: {
                ...standardStore.mutations
            },
            actions: {
                ...standardStore.actions(config),
                clone(context, id){
                    tools.report('CLONING ', id);
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = http.getUrl(config.ep) + '/' + id + '/clone';
                    tools.report('EP ', ep);

                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEl]) {
                                    context.commit('prependRow', res['data'][config.respEl]);
                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    if (config.stopLoading) {
                                        context.commit(config.stopLoading);
                                    }
                                },
                            );
                    });
                }
            }
        }
    }
}
