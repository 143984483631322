import tools from '../../libs/tools';
import http from '../../libs/http';

const config = {
    ep: "/v1/stats/by_slots/funnel_events",
    respEls: "stats",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale"
};

const getLastFromSource = (source, hoursPassed) => {
    const keys = Object.keys(source);
    if (!source || keys.length < hoursPassed) return 0;
    keys.sort();
    const idx = keys.length - hoursPassed;
    const key = keys[idx];
    return source[key] || 0;
};

export default {
    init(ev){
        return {
            state: {
                all_values: {},
                all_deltas: {},
                android_values: {},
                android_deltas: {},
                web_values: {},
                web_deltas: {},
                ios_values: {},
                ios_deltas: {}
            },
            getters: {
                all_values: state => state.all_values,
                all_deltas: state => state.all_deltas,
                android_values: state => state.android_values,
                android_deltas: state => state.android_deltas,
                web_values: state => state.web_values,
                web_deltas: state => state.web_deltas,
                ios_values: state => state.ios_values,
                ios_deltas: state => state.ios_deltas,
                allGetLastValue: state => {
                    return hoursPassed => {
                        return getLastFromSource(state.all_values, hoursPassed);
                    }
                },
                androidGetLastValue: state => {
                    return hoursPassed => {
                        return getLastFromSource(state.android_values, hoursPassed);
                    }
                },
                iosGetLastValue: state => {
                    return hoursPassed => {
                        return getLastFromSource(state.ios_values, hoursPassed);
                    }
                },
                webGetLastValue: state => {
                    return hoursPassed => {
                        return getLastFromSource(state.web_values, hoursPassed);
                    }
                }
            },
            mutations: {
                resetValues: (state, values) => {
                    const newState = {
                        all_values: {},
                        all_deltas: {},
                        android_values: {},
                        android_deltas: {},
                        web_values: {},
                        web_deltas: {},
                        ios_values: {},
                        ios_deltas: {}
                    };
                    ["all", "ios", "web", "android"].forEach(plat => {
                        const kValues = plat + "_values";
                        const kDeltas = plat + "_deltas";
                        if (values[plat]) {
                            if (values[plat]["values"]) {
                                newState[kValues] = values[plat]["values"];
                            }
                            if (values[plat]["deltas"]) {
                                newState[kDeltas] = values[plat]["deltas"];
                            }
                        }
                    })

                    Object.keys(newState).forEach(k => {
                        state[k] = newState[k];
                    })
                }
            },
            actions: {
                loadStatsData(context){
                    tools.report('loadData ');
                    context.commit(config.startLoading);
                    const ep = http.getUrl(config.ep);
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep, {params: {last: 24, event: ev}})
                            .then(res => {
                                if (res['data'] && res['data']['stats']) {
                                    context.commit('resetValues', res['data']['stats'])
                                    resolve(res['data']['stats']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    context.commit(config.stopLoading);
                                },
                            );
                    });
                }
            }
        }
    },
    config: config
}
