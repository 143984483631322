const List = () => import('../../views/cont/podcasts/List');
const Edit = () => import('../../views/cont/podcasts/Edit');

export default {
    Create: {
        path: 'cont/podcasts/new',
        name: 'cont_podcasts_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/cont/podcasts',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Podcast', to: '/app/cont/podcasts' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'cont/podcasts/:id/:lang?',
        name: 'cont_podcasts_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: Number(route.params.id),
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/podcasts',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Podcasts', to: '/app/cont/podcasts'},
                { label: 'Modifica Podcasts' }],
        },
    },
    List: {
        path: 'cont/podcasts',
        name: 'cont_podcasts_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Podcast' }],
        },
    }
}
