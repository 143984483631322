import tools from '../../../libs/tools';
import http from '../../../libs/http';

const config = {
    ep: '/v1/web_redirects',
    reqEl: 'web_redirect',
    respEls: 'web_redirects',
    respEl: 'web_redirect',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
    methods: {
        savePlain: 'put',
    },
};

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
            },
            getters: {
                ...standardStore.getters,
            },
            mutations: {
                ...standardStore.mutations,
            },
            actions: {
                ...standardStore.actions(config),
                delete(context, id) {
                    tools.report('Delete ', id);
                    context.commit('startLoading');
                    const ep = config.ep + '/' + id;
                    return http.api.boolSimpleDeletePromise(context, config, ep);
                },
            },
        };
    },
};
