import http from '../../../libs/http';

const config = {
    ep: "v2/complete_books",
    reqEl: "book",
    respEls: "books",
    respEl: "book",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale"
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                searched: []
            },
            getters: {
                ...standardStore.getters,
                searched: state => state.searched
            },
            mutations: {
                ...standardStore.mutations,
                setSearched(state, values){
                    if (values) state.searched = values;
                },
                clearSearch(state){
                    state.searched = [];
                }
            },
            actions: {
                ...standardStore.actions(config),
                search(context, params){
                    context.commit('clearSearch');
                    context.commit('startLoading');
                    const sendData = {
                        text: "",
                        limit: 8,
                        categories: [],
                        tags: [],
                        progress: []
                    }
                    if (params.text) sendData.text = params.text;
                    if (params.limit) sendData.limit = params.limit;
                    //Complete this function to consider other params if needed
                    const ep = "/v1/searches";
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, JSON.stringify(sendData))
                            .then(res => {
                                if (res && res.data && res.data[config.respEls]){
                                    context.commit('setSearched', res.data[config.respEls]);
                                    resolve(res.data[config.respEls]);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    })
                }
            }
        }
    }
}
