import Psychologists from './psychologists/store';
import Patients from './patients/store';

export default {
    init(standardStore){
        return {
            namespaced: true,
            state(){
                return {}
            },
            getters: {},
            mutations: {},
            actions: {},
            modules: {
                Psychologists: Psychologists.init(standardStore),
                Patients: Patients.init(standardStore),
            }
        }
    },

}
