import Abtests from './abtests/store';

export default {
    init(standardStore){
        return {
            namespaced: true,
            state(){
                return {}
            },
            getters: {},
            mutations: {},
            actions: {},
            modules: {
                Abtests: Abtests.init(standardStore),
            }
        }
    },

}
