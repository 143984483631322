import tools from '../../../libs/tools';
import http from '../../../libs/http';

const config = {
    ep: '/v1/cms_users',
    reqEl: 'user',
    respEls: 'users',
    respEl: 'user',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
    methods: {
        savePlain: 'put',
    },
};

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
            },
            getters: {
                ...standardStore.getters,
            },
            mutations: {
                ...standardStore.mutations,
            },
            actions: {
                ...standardStore.actions(config),
                grantAccess(context, data){
                    tools.report('Grant access to ', data.id);
                    const ep = config.ep + '/' + data.id;
                    const payload = {
                        role_id: data.role_id
                    }
                    return http.api.boolDonePromise(context, config, ep, payload);
                },
                changeRole(context, data){
                    tools.report('Patch Role ', data.id);
                    const ep = config.ep + '/' + data.id;
                    const payload = {
                        role_id: data.role_id
                    }
                    return http.api.boolPatchDonePromise(context, config, ep, payload);
                },
                delete(context, id) {
                    tools.report('Delete ', id);
                    context.commit('startLoading');
                    const ep = config.ep + '/' + id;
                    return http.api.boolSimpleDeletePromise(context, config, ep);
                },
            },
        };
    },
};
