const View = () => import('../../views/stats/api/live/View');

export default {
    View: {
        path: 'stats/api/live',
        name: 'stats_api_live',
        exact: true,
        component: View,
        meta: {
            breadcrumb: [{ label: 'STATISTICHE' }, { label: 'API' }],
        },
    }
}
