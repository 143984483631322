<template>
    <Button :label="label"
            class="p-button-raised p-button-success mr-2 mb-2"
            :disabled='load'
            :icon="icon"/>
</template>
<script>

export default {
    computed: {
        icon(){
            return this.load ? "pi pi-spin pi-spinner" : "pi pi-save";
        }
    },
    props: {
        load: {
            type: Boolean,
            default(){
                return false;
            }
        },
        label: {
            type: String,
            default(){
                return "Salva";
            }
        }
    }
}
</script>
