import http from '../../../libs/http';

const config = {
    ep: '/v1/domains',
    reqEl: 'domain',
    respEls: '',
    respEl: 'domain',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
    methods: {
        savePlain: 'post',
    },
};

export default {
    init() {
        return {
            namespaced: true,
            state: {
                blacklisted: [],
                whitelisted: [],
                loading: false,
            },
            getters: {
                blacklisted: state => state.blacklisted,
                whitelisted: state => state.whitelisted,
                loading: state => state.loading,
            },
            mutations: {
                setBlacklisted: (state, blacklisted) => {
                    state.blacklisted = blacklisted;
                },
                setWhitelisted: (state, whitelisted) => {
                    state.whitelisted = whitelisted;
                },
                setLoading: (state, loading) => {
                    state.loading = loading;
                },
            },
            actions: {
                search(context, domain) {
                    context.commit('setLoading', true);
                    const ep = config.ep + '/check';
                    const payload = {
                        domain: domain,
                    };
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, payload)
                            .then(res => {
                                if (res && res['data']) {
                                    //mutate
                                    const data = res['data'];
                                    if (data['blacklisted']) {
                                        context.commit('setBlacklisted', data['blacklisted']);
                                    } else {
                                        context.commit('setBlacklisted', []);
                                    }

                                    if (data['whitelisted']) {
                                        context.commit('setWhitelisted', data['whitelisted']);
                                    } else {
                                        context.commit('setWhitelisted', []);
                                    }

                                    resolve(res['data']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    if (config.stopLoading) {
                                        context.commit('setLoading', false);
                                    }
                                },
                            );
                    });
                },
                delete(context, {domain, list}) {
                    context.commit('setLoading', true);
                    const ep = config.ep + '/' + list + '/' + domain;
                    return new Promise((resolve, reject) => {
                        http.api.client.delete(ep)
                            .then(res => {
                                if (res && res['data'] && res['data']['done']) {
                                    resolve(true);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    if (config.stopLoading) {
                                        context.commit('setLoading', false);
                                    }
                                },
                            );
                    });
                },
                add(context, {domain, list}) {
                    context.commit('setLoading', true);
                    const payload = {
                        domain: domain,
                    };
                    const ep = config.ep + '/' + list ;
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, payload)
                            .then(res => {
                                if (res && res['data'] && res['data']['done']) {
                                    resolve(true);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    if (config.stopLoading) {
                                        context.commit('setLoading', false);
                                    }
                                },
                            );
                    });
                },
            },
        };
    },
};
