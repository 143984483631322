const List = () => import('../../views/analytics/abtests/List');
const Edit = () => import('../../views/analytics/abtests/Edit');
const Compare = () => import('../../views/analytics/abtests/Compare');

export default {
    Create: {
        path: 'analytics/abtests/new',
        name: 'anal_abtests_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/analytics/abtests',
            breadcrumb: [
                { label: 'ANALISI' },
                { label: 'Abtests', to: '/app/analytics/abtests' },
                { label: 'Nuovo' }],
        },
    },
    Compare: {
        path: 'analytics/abtests/:ab_1/:v_1/:ab_2/:v_2',
        name: 'anal_abtests_compare', //CHANGE
        exact: true,
        props: (route) => {
            return {
                ab_1: parseInt(route.params.ab_1, 10),
                ab_2: parseInt(route.params.ab_2, 10),
                v_1: route.params.v_1,
                v_2: route.params.v_2,
            };
        },
        component: Compare,
        meta: {
            basePath: '/app/analytics/abtests',
            breadcrumb: [
                { label: 'ANALISI' },
                { label: 'Abtests', to: '/app/analytics/abtests'},
                { label: 'Compara Abtest' }],
        },
    },
    Update: {
        path: 'analytics/abtests/:id',
        name: 'anal_abtests_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: parseInt(route.params.id, 10)
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/analytics/abtests',
            breadcrumb: [
                { label: 'ANALISI' },
                { label: 'Abtests', to: '/app/analytics/abtests'},
                { label: 'Modifica Abtest' }],
        },
    },
    List: {
        path: 'analytics/abtests',
        name: 'anal_abtests_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'ANALISI' }, { label: 'Abtests' }],
        },
    }
}
