<template>
    <img src="assets/demo/flags/flag_placeholder.png" :alt="name" :class="flagClass(code)" :width="width" />
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ""
        },
        code: {
            type: String,
            default: ""
        },
        width: {
            type: Number,
            default: 30
        },
    },
    methods: {
      flagClass(cc){
          if (cc === "en") cc = "gb";
          return 'flag flag-' + cc;
      }
    }
}
</script>
