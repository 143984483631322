const List = () => import('../../views/cont/quotes/List');
const Edit = () => import ('../../views/cont/quotes/Edit');

export default {
    Create: {
        path: 'cont/quotes/new',
        name: 'cont_quotes_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: "" },
        meta: {
            basePath: '/app/cont/quotes',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Citazioni', to: '/app/cont/quotes' },
                { label: 'Nuova' }],
        },
    },
    Update: {
        path: 'cont/quotes/:id/:lang?',
        name: 'cont_quotes_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id,
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/quotes',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Citazioni', to: '/app/cont/quotes'},
                { label: 'Modifica Citazione' }],
        },
    },
    List: {
        path: 'cont/quotes',
        name: 'cont_quotes_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Citazioni' }],
        },
    }
}
