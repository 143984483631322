import http from '../../../libs/http';

const config = {
    ep: "v1/magazine_posts",
    reqEl: "magazine_post",
    respEls: "magazine_posts",
    respEl: "magazine_post",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale"
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: standardStore.state,
            getters: standardStore.getters,
            mutations: standardStore.mutations,
            actions: {
                ...standardStore.actions(config),
                search(context, text){
                    context.commit('startLoading');
                    const sendData = {
                        text: text,
                    };
                    const ep = '/v1/magazine_posts/searches';
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, JSON.stringify(sendData))
                            .then(res => {
                                if (res && res.data && res.data[config.respEls]) {
                                    context.commit(config.storeRows, res.data[config.respEls]);
                                    resolve(res.data[config.respEls]);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    })
                }
            }
        }
    }
}
