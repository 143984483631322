import http from '../../libs/http';

export default {
    namespaced: true,
    state() {
        return {
            loading: false,
            books: {},
            articles: {},
            quotes: {},
            publishers: {},
            authors: {},
            the_updates: {},
            loading_books_x_cat: false,
            books_x_cat: {},
            loading_articles_x_cat: false,
            articles_x_cat: {},

        };
    },
    getters: {
        loading: state => state.loading,
        books: state => state.books,
        articles: state => state.articles,
        the_updates: state => state.the_updates,
        loading_books_x_cat: state => state.loading_books_x_cat,
        quotes: state => state.quotes,
        loading_articles_x_cat: state => state.loading_articles_x_cat,
        books_x_cat: state => state.books_x_cat,
        articles_x_cat: state => state.articles_x_cat,
        books_x_cat_x_lang: state => (lang) => {
            return state.books_x_cat[lang] || [];
        },
        articles_x_cat_x_lang: state => (lang) => {
            return state.articles_x_cat[lang] || [];
        },
    },
    mutations: {
        setArticlesXCat(state, values) {
            state.articles_x_cat = values;
        },
        setBooksXCat(state, values) {
            state.books_x_cat = values;
        },
        startLoading(state) {
            state.loading = true;
        },
        startLoadingArticlesXCat(state) {
            state.loading_articles_x_cat = true;
        },
        stopLoadingArticlesXCat(state) {
            state.loading_articles_x_cat = false;
        },
        startLoadingBooksXCat(state) {
            state.loading_books_x_cat = true;
        },
        stopLoadingBooksXCat(state) {
            state.loading_books_x_cat = false;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setBooks(state, value) {
            state.books = {};
            if (value) {
                state.books = value;
            }
        },
        setArticles(state, value) {
            state.articles = {};
            if (value) {
                state.articles = value;
            }
        },
        setTheUpdates(state, value) {
            state.the_updates = {};
            if (value) {
                state.the_updates = value;
            }
        },
        setQuotes(state, value) {
            state.quotes = {};
            if (value) {
                state.quotes = value;
            }
        },
    },
    actions: {
        getArticlesXCat(context, refresh) {
            context.commit('startLoadingArticlesXCat');
            const ep = http.getUrl('/v1/dashboard/by_category/articles');
            const qp = {};
            if (refresh) qp['refresh']="yes";

            return new Promise((resolve, reject) => {
                http.api.client.request({
                        url: ep,
                        method: 'get',
                        params: qp
                    }
                )
                    .then(res => {
                        if (res && res['data'] && res['data']['Stats']) {
                            context.commit('setArticlesXCat', res['data']['Stats']);
                            resolve();
                        } else {
                            reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                        }
                    })
                    .catch(err => {
                        reject(http.formatError('server-error', err));
                    })
                    .finally(() => {
                        context.commit('stopLoadingArticlesXCat');
                    });
            });
        },
        getBooksXCat(context, refresh) {
            context.commit('startLoadingBooksXCat');
            const qp = {};
            if (refresh) qp['refresh']="yes";

            const ep = http.getUrl('/v1/dashboard/by_category/books');
            return new Promise((resolve, reject) => {
                http.api.client.request({
                    url: ep,
                    method: 'get',
                    params: qp
                })
                    .then(res => {
                        if (res && res['data'] && res['data']['Stats']) {
                            context.commit('setBooksXCat', res['data']['Stats']);
                            resolve();
                        } else {
                            reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                        }
                    })
                    .catch(err => {
                        reject(http.formatError('server-error', err));
                    })
                    .finally(() => {
                        context.commit('stopLoadingBooksXCat');
                    });
            });
        },
        getStats(context) {
            context.commit('startLoading');
            const ep = http.getUrl('/v1/dashboard');
            return new Promise((resolve, reject) => {
                http.api.client.get(ep)
                    .then(res => {
                        if (res && res['data'] && res['data']['Stats']) {
                            const dash = res['data']['Stats'];
                            if (dash['Cont']) {
                                const cont = dash['Cont'];
                                context.commit('setBooks', cont['Books']);
                                context.commit('setArticles', cont['Articles']);
                                context.commit('setTheUpdates', cont['TheUpdates']);
                                context.commit('setQuotes', cont['Quotes']);
                            }
                            resolve();
                        } else {
                            reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                        }
                    })
                    .catch(err => {
                        reject(http.formatError('server-error', err));
                    })
                    .finally(() => {
                        context.commit('stopLoading');
                    });
            });
        },
    },
};
