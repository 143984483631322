const List = () => import('../../views/magazine/posts/List');
const Edit = () => import('../../views/magazine/posts/Edit');

export default {
    Create: {
        path: 'magazine/posts/new',
        name: 'magazine_posts_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/magazine/posts',
            breadcrumb: [
                { label: 'MAGAZINE' },
                { label: 'Posts', to: '/app/magazine/posts' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'magazine/posts/:id/:lang?',
        name: 'magazine_posts_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: Number(route.params.id),
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/magazine/posts',
            breadcrumb: [
                { label: 'MAGAZINE' },
                { label: 'Posts', to: '/app/magazine/posts'},
                { label: 'Modifica Post' }],
        },
    },
    List: {
        path: 'magazine/posts',
        name: 'magazine_posts_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'MAGAZINE' }, { label: 'Posts' }],
        },
    }
}
