import Users from './users/store';
import Cms from './cms/store';
import Companies from './companies/store';
import CompanyTeams from './company_teams/store';
import CompanyUsers from './company_users/store';
import CompanyTeamSkills from './team_skills/store';
import CompanyTeamPaths from './team_paths/store';
import CompanyDashboardAccess from './company_dashboard_users/store';
import B2BRoles from './b2b_roles/store';

export default {
    init(standardStore){
        return {
            namespaced: true,
            state(){
                return {}
            },
            getters: {},
            mutations: {},
            actions: {},
            modules: {
                Users: Users.init(standardStore),
                Cms: Cms.init(standardStore),
                Companies: Companies.init(standardStore),
                CompanyTeams: CompanyTeams.init(standardStore),
                CompanyTeamSkills: CompanyTeamSkills.init(standardStore),
                CompanyTeamPaths: CompanyTeamPaths.init(standardStore),
                CompanyUsers: CompanyUsers.init(standardStore),
                CompanyDashboardAccess: CompanyDashboardAccess.init(standardStore),
                B2BRoles: B2BRoles.init(standardStore)
            }
        }
    },

}
