import common from '../common';

const commonStore = common.init("checkout_started");

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                ...commonStore.state
            },
            getters: {
                ...standardStore.getters,
                ...commonStore.getters
            },
            mutations: {
                ...standardStore.mutations,
                ...commonStore.mutations
            },
            actions: {
                ...standardStore.actions(commonStore.config),
                ...commonStore.actions
            }
        }
    }
}
