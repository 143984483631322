const List = () => import('../../views/users/b2b_dashboard/List');

export default {
    List: {
        path: 'users/companies/:company_id/dashboard_b2b',
        name: 'users_cms_b2b_users_list',
        exact: true,
        props: (route) => {
            return {
                company_id: parseInt(route.params.company_id, 10)
            };
        },
        component: List,
        meta: {
            breadcrumb: [{ label: 'UTENTI' }, { label: 'Accesso Dashboard B2B' }],
        },
    }
}
