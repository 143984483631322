const List = () => import('../../views/cont/skillsV2/List');
const Edit = () => import('../../views/cont/skillsV2/Edit');

export default {
    Create: {
        path: 'cont/skills-v2/new',
        name: 'cont_skillsV2_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/cont/skills-v2',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Skills', to: '/app/cont/skills-v2' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'cont/skills-v2/:id/:lang?',
        name: 'cont_skillsV2_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: Number(route.params.id),
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/skills-v2',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Skills', to: '/app/cont/skills-v2'},
                { label: 'Modifica Skill' }],
        },
    },
    List: {
        path: 'cont/skills-v2',
        name: 'cont_skillsV2_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Skills' }],
        },
    }
}
