const List = () => import('../../views/chatbots/helpdesk/List');
const Edit = () => import('../../views/chatbots/helpdesk/Edit');


export default {
    List: {
        path: 'chatbots/helpdesk',
        name: 'chatbots_helpdesk_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CHATBOTS' }, { label: 'Helpdesk' }, { label: 'Domande' }],
        },
    },
    Create: {
        path: 'chatbots/helpdesk/new',
        name: 'chatbots_helpdesk_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: "" },
        meta: {
            basePath: '/app/chatbots/helpdesk',
            breadcrumb: [
                { label: 'CHATBOTS' },
                { label: 'Helpdesk' },
                { label: 'Domande', to: '/app/chatbots/helpdesk' },
                { label: 'Nuova domanda' }],
        },
    },
    Update: {
        path: 'chatbots/helpdesk/:id',
        name: 'chatbots_helpdesk_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id,
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/chatbots/helpdesk',
            breadcrumb: [
                { label: 'CHATBOTS' },
                { label: 'Helpdesk' },
                { label: 'Domande', to: '/app/chatbots/helpdesk' },
                { label: 'Modifica domanda' }],
        },
    },
}
