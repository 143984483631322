const List = () => import('../../views/users/company_teams/List');
const Edit = () => import('../../views/users/company_teams/Edit');

export default {
    Create: {
        path: 'users/companies/:company_id/company_teams/new',
        name: 'users_company_teams_new', //CHANGE
        exact: true,
        component: Edit,
        props: (route) => {
            return {
                company_id: parseInt(route.params.company_id, 10)
            };
        },
        meta: {
            basePath: '/app/users/companies/:company_id/company_teams',
            breadcrumb: [
                { label: 'B2B' },
                { label: 'Aziende', to: '/app/users/companies' }, //Two more levels
                { label: 'Nuova' }],
        },
    },
    Update: {
        path: 'users/companies/:company_id/company_teams/:id',
        name: 'users_company_teams_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: parseInt(route.params.id, 10),
                company_id: parseInt(route.params.company_id, 10)
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/users/companies',
            breadcrumb: [
                { label: 'B2B' },
                { label: 'Aziende', to: '/app/users/companies'}
            ],
        },
    },
    List: {
        path: 'users/companies/:company_id/company_teams',
        name: 'users_company_teams_list',
        exact: true,
        props: (route) => {
            return {
                company_id: parseInt(route.params.company_id, 10)
            };
        },
        component: List,
        meta: {
            breadcrumb: [{ label: 'B2B' }, { label: 'Aziende', to: '/app/users/companies' }],
        },
    }
}
