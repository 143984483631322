import tools from '../../../libs/tools';
import http from '../../../libs/http';

const config = {
    ep: "/v1/task/purchaseimport",
    reqEl: "task",
    respEls: "tasks",
    respEl: "task",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale",
    methods: {
        savePlain: 'put'
    }
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: standardStore.state,
            getters: standardStore.getters,
            mutations: standardStore.mutations,
            actions: {
                ...standardStore.actions(config),
                refreshTask(context, id){
                    tools.report('REFRESH TASK ', id);
                    const ep = http.getUrl(config.ep) + '/' + id ;
                    tools.report('EP ', ep);
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep, )
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEl]) {
                                    const task = res['data'][config.respEl];
                                    context.commit('rowsChange', res['data'][config.respEl]);
                                    if (task.status && (task.status === 1 || task.status === 2)){
                                        setTimeout(() => {
                                            context.dispatch('refreshTask', id).then();
                                        }, 3000);
                                    }

                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    if (config.stopLoading) {
                                        context.commit(config.stopLoading);
                                    }
                                },
                            );
                    });
                },
                changeStatus(context, {status, id}){
                    tools.report('CHANGE STATUS ', id);
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    const ep = http.getUrl(config.ep) + '/status/' + id ;
                    tools.report('EP ', ep);
                    return new Promise((resolve, reject) => {
                        http.api.client.patch(ep, {status: status})
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEl]) {
                                    context.commit('rowsChange', res['data'][config.respEl]);

                                    if (res['data'][config.respEl].status && (res['data'][config.respEl].status === 1 || res['data'][config.respEl].status === 2)){
                                        setTimeout(() => {
                                            context.dispatch('refreshTask', id).then();
                                        }, 3);
                                    }

                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    if (config.stopLoading) {
                                        context.commit(config.stopLoading);
                                    }
                                },
                            );
                    });
                }
            }
        }
    }
}
