import http from '../../../libs/http';

const config = {
    ep: "/v1/stats/subs/searches",
    reqEl: "stat",
    respEls: "Stats",
    respEl: "stat",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "storeData",
    storeRow: "setRow",
    localeKey: "locale"
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                from: '',
                to: '',
                providers: {
                    'pp_1': 'manual',
                    'pp_2': 'web',
                    'pp_3': 'ios',
                    'pp_4': 'android',
                    'pp_5': 'slashers',
                    'pp_8': 'corporate',
                },
                active_subscriptions: [],
                monthly_subscriptions: [],
                tri_monthly_subscriptions: [],
                six_monthly_subscriptions: [],
                new_subscriptions: [],
                yearly_subscriptions: [],
                manual_subscriptions: [],
                web_subscriptions: [],
                ios_subscriptions: [],
                android_subscriptions: [],
                corporate_subscriptions: [],
                slashers_subscriptions: []
            },
            getters: {
                ...standardStore.getters,
                from: state => state.from,
                to: state => state.to,
                providers: state => state.providers,
                active_subscriptions: state => state.active_subscriptions,
                monthly_subscriptions: state => state.monthly_subscriptions,
                tri_monthly_subscriptions: state => state.tri_monthly_subscriptions,
                six_monthly_subscriptions: state => state.six_monthly_subscriptions,
                new_subscriptions: state => state.new_subscriptions,
                yearly_subscriptions: state => state.yearly_subscriptions,
                manual_subscriptions: state => state.manual_subscriptions,
                web_subscriptions: state => state.web_subscriptions,
                ios_subscriptions: state => state.ios_subscriptions,
                android_subscriptions: state => state.android_subscriptions,
                corporate_subscriptions: state => state.corporate_subscriptions,
                slashers_subscriptions: state => state.slashers_subscriptions
            },
            mutations: {
                ...standardStore.mutations,
                setFrom(state, from){
                    state.from = from;
                },
                setTo(state, to){
                    state.to = to;
                },
                storeData(state, rows){
                    state.rows = rows;
                    const active_subscriptions = [];
                    const tri_monthly_subscriptions = [];
                    const six_monthly_subscriptions = [];
                    const monthly_subscriptions = [];
                    const yearly_subscriptions = [];
                    const manual_subscriptions = [];
                    const web_subscriptions = [];
                    const ios_subscriptions = [];
                    const android_subscriptions = [];
                    const slashers_subscriptions = [];
                    const corporate_subscriptions = [];

                    rows.forEach(row => {
                        const d = new Date(Date.parse(row['date']));
                        const active_subscription = {
                            Giorno: d,
                            Totale: row['active'],
                            Web: 0,
                            Android: 0,
                            Ios: 0,
                            Manual: 0,
                            Slashers: 0,
                            Corporate: 0
                        };


                        const details = JSON.parse(row['details']);
                        //console.log(details);
                        if (details['xpr']){
                            //console.log(details['xpr']);
                            for (const [key, value] of Object.entries(details['xpr'])) {
                                const header = {
                                    Giorno: d,
                                    Totale: 0,
                                    Mensile: 0,
                                    Trimestrale: 0,
                                    Semestrale: 0,
                                    Annuale: 0
                                };
                                value.forEach(line => {
                                    header.Totale += line['num'];
                                    switch (line['dur']){
                                        case 'm1':
                                            header.Mensile += line['num'];
                                            break;
                                        case 'm3':
                                            header.Trimestrale += line['num'];
                                            break;
                                        case 'm6':
                                            header.Semestrale += line['num'];
                                            break;
                                        case 'y1':
                                            header.Annuale += line['num'];
                                            break;
                                    }
                                });
                                const detail = value;
                                switch (key){
                                    case 'pp_1':
                                        detail.forEach(line => {
                                            active_subscription['Manual'] += line['num'];
                                        })
                                        manual_subscriptions.push({...header})
                                        break;
                                    case 'pp_2':
                                        detail.forEach(line => {
                                            active_subscription['Web'] += line['num'];
                                        })
                                        web_subscriptions.push({...header})
                                        break;
                                    case 'pp_3':
                                        detail.forEach(line => {
                                            active_subscription['Ios'] += line['num'];
                                        })
                                        ios_subscriptions.push({...header})
                                        break;
                                    case 'pp_4':
                                        detail.forEach(line => {
                                            active_subscription['Android'] += line['num'];
                                        })
                                        android_subscriptions.push({...header})
                                        break;
                                    case 'pp_5':
                                        detail.forEach(line => {
                                            active_subscription['Slashers'] += line['num'];
                                        })
                                        slashers_subscriptions.push({...header})
                                        break;
                                    case 'pp_8':
                                        detail.forEach(line => {
                                            active_subscription['Corporate'] += line['num'];
                                        })
                                        corporate_subscriptions.push({...header})
                                        break;
                                }
                            }
                        }
                        if (details['xpl']){
                            Object.keys(details['xpl']).forEach(sub_duration => {
                                const lineDetail = {
                                    Giorno: d,
                                    Totale: 0,
                                    Web: 0,
                                    Android: 0,
                                    Ios: 0,
                                    Manual: 0,
                                    Slashers: 0,
                                    Corporate: 0
                                };
                                details['xpl'][sub_duration].forEach((detail) => {
                                    const key = detail['pid'];
                                    lineDetail['Totale'] += detail['num'];
                                    switch (key){
                                        case 'pp_1':
                                            lineDetail['Manual'] += detail['num'];
                                            break;
                                        case 'pp_2':
                                            lineDetail['Web'] += detail['num'];
                                            break;
                                        case 'pp_3':
                                            lineDetail['Ios'] += detail['num'];
                                            break;
                                        case 'pp_4':
                                            lineDetail['Android'] += detail['num'];
                                            break;
                                        case 'pp_5':
                                            lineDetail['Slashers'] += detail['num'];
                                            break;
                                        case 'pp_8':
                                            lineDetail['Corporate'] += detail['num'];
                                            break;
                                    }
                                })
                                switch (sub_duration){
                                    case 'm1':
                                        monthly_subscriptions.push({...lineDetail});
                                        break;
                                    case 'm3':
                                        tri_monthly_subscriptions.push({...lineDetail});
                                        break;
                                    case 'm6':
                                        six_monthly_subscriptions.push({...lineDetail});
                                        break;
                                    case 'y1':
                                        yearly_subscriptions.push({...lineDetail});
                                        break;
                                }
                            })
                        }
                        active_subscriptions.push(active_subscription)
                    })
                    state.active_subscriptions = active_subscriptions;
                    state.yearly_subscriptions = yearly_subscriptions;
                    state.six_monthly_subscriptions = six_monthly_subscriptions;
                    state.tri_monthly_subscriptions = tri_monthly_subscriptions;
                    state.monthly_subscriptions = monthly_subscriptions;

                    state.manual_subscriptions = manual_subscriptions;
                    state.web_subscriptions = web_subscriptions;
                    state.ios_subscriptions = ios_subscriptions;
                    state.android_subscriptions = android_subscriptions;
                    state.slashers_subscriptions = slashers_subscriptions;
                    state.corporate_subscriptions = corporate_subscriptions;
                }
            },
            actions: {
                ...standardStore.actions(config),
                search(context){
                    context.commit(config.storeRows, []);
                    context.commit('startLoading');
                    const payload = {
                        from: context.getters.from,
                        to: context.getters.to
                    };
                    return http.api.postPromise(context, config, config.ep, payload);
                }
            }
        }
    }
}
