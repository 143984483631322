const List = () => import('../../views/users/cms/List');

export default {
    List: {
        path: 'users/cms_users',
        name: 'users_cms_users_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'UTENTI' }, { label: 'Accesso CMS' }],
        },
    }
}
