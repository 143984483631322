import http from '../../../libs/http';

const config = {
    ep: 'v1/abtests',
    reqEl: 'abtest',
    respEls: 'abtests',
    respEl: 'abtest',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
};

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: standardStore.state,
            getters: {
                ...standardStore.getters,
                resources: () => {
                    return {
                        VISITORS: 'visitors',
                        USERS: 'users',
                        HOME_VIEWED: 'home_viewed',
                        BOOK_FEEDBACKS: 'book_feedbacks',
                        CATEGORY_FEEDBACKS: 'category_feedbacks',
                        SKILL_FEEDBACKS: 'skill_feedbacks',
                        FAVOURITE_BOOKS: 'favourite_books',
                        BOOKS_STARTED: 'books_started',
                        BOOKS_COMPLETED: 'books_completed',
                        CHECKOUT: 'checkout',
                        SUBSCRIPTIONS: 'subscriptions',
                    };
                },
            },
            mutations: standardStore.mutations,
            actions: {
                ...standardStore.actions(config),
                compare(context, { ab1, ab2, v1, v2, from1, from2, what }) {
                    const payload = {
                        what: what,
                        ab_1: ab1,
                        ab_2: ab2,
                        v1: v1,
                        v2: v2,
                    };
                    const ep = config.ep + '/comparison';
                    if (from1) {
                        payload['data_start_1'] = from1;
                    }
                    if (from2) {
                        payload['data_start_2'] = from2;
                    }
                    return new Promise((resolve, reject) => {
                        context.commit('startLoading');
                        http.api.client
                            .post(ep, payload)
                            .then(res => {
                                if (res && res['data']){
                                    resolve(res['data']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                                context.commit('stopLoading');
                            })
                            .catch(err => {
                                context.commit('stopLoading');
                                reject(http.formatError('server-error', err));
                            })
                    });
                },


            },
        };
    },
};
