import http from '../../../libs/http';

const config = {
    ep: "/v1/psycho/psychologists",
    ep_av: "/v1/psycho/psychologists/:id/availability",
    reqEl: "psychologist",
    respEls: "psychologists",
    respEl: "psychologist",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale"
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: standardStore.state,
            getters: {
                ...standardStore.getters,
                getPsychoById: state => function(id){
                    if (!state['rows']) return null;
                    const filtered = state['rows'].filter(row => row.id === id);
                    if (filtered.length > 0) {
                        return filtered[0];
                    }
                    return null;
                }
            },
            mutations: standardStore.mutations,
            actions: {
                ...standardStore.actions(config),
                toggleAvailability(context, id){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }

                    const ep = config['ep_av'].replace(":id", id) ;


                    return new Promise((resolve, reject) => {
                        http.api.client.patch(ep, {})
                            .then(res => {
                                if (res && res['data']) {
                                    resolve(res['data']);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                if (config.stopLoading) {
                                    context.commit(config.stopLoading);
                                }
                            });
                    });
                }
            }
        }
    }
}
