const List = () => import('../../views/cont/quiz/List');
const Edit = () => import('../../views/cont/quiz/Edit');

export default {
    Update: {
        path: 'cont/quiz/:id',
        name: 'cont_quiz_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/quiz',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Tutti i Quiz', to: '/app/cont/quiz'},
                { label: 'Modifica Quiz' }],
        },
    },
    List: {
        path: 'cont/quiz',
        name: 'cont_quiz_list',
        exact: true,
        component: List,
        meta: {
            basePath: '/app/cont/quiz',
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Quiz sui Libri' }],
        },
    }
}
