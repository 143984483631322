const List = () => import('../../views/cont/lookups/authors/List');
const Edit = () => import ('../../views/cont/lookups/authors/Edit');

export default {
    Create: {
        path: 'cont/authors/new',
        name: 'cont_authors_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: "" },
        meta: {
            basePath: '/app/cont/authors',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Lookups' },
                { label: 'Authors', to: '/app/cont/authors' },
                { label: 'Nuovo' }],
        },
    },
    Update: {
        path: 'cont/authors/:id/:lang?',
        name: 'cont_authors_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id,
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/authors',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Lookups' },
                { label: 'Authors', to: '/app/cont/authors'},
                { label: 'Modifica Autore' }],
        },
    },
    List: {
        path: 'cont/authors',
        name: 'cont_authors_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Lookups' }, { label: 'Autori' }],
        },
    }
}
