const List = () => import('../../views/cont/bookseries/List');
const Edit = () => import ('../../views/cont/bookseries/Edit');

export default {
    Create: {
        path: 'cont/bookseries/new',
        name: 'bookseries', //CHANGE
        exact: true,
        component: Edit,
        props: { id: "" },
        meta: {
            basePath: '/app/cont/bookseries',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Serie di libri', to: '/app/cont/bookseries' },
                { label: 'Nuova' }],
        },
    },
    Update: {
        path: 'cont/bookseries/:id/:lang?',
        name: 'cont_bookseries_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: route.params.id,
                lang: route.params.lang || ''
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/cont/bookseries',
            breadcrumb: [
                { label: 'CONTENUTI' },
                { label: 'Serie di libri', to: '/app/cont/bookseries'},
                { label: 'Modifica Serie di libri' }],
        },
    },
    List: {
        path: 'cont/bookseries',
        name: 'cont_bookseries_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'CONTENUTI' }, { label: 'Serie di libri' }],
        },
    }
}
