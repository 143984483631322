const List = () => import('../../views/users/companies/List');
const Edit = () => import('../../views/users/companies/Edit');

export default {
    Create: {
        path: 'users/companies/new',
        name: 'users_companies_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/users/companies',
            breadcrumb: [
                { label: 'UTENTI' },
                { label: 'Aziende', to: '/app/users/companies' },
                { label: 'Nuova' }],
        },
    },
    Update: {
        path: 'users/companies/:id',
        name: 'users_companies_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: parseInt(route.params.id, 10)
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/users/companies',
            breadcrumb: [
                { label: 'UTENTI' },
                { label: 'Aziende', to: '/app/users/companies'},
                { label: 'Inserisci Dati Azienda' }],
        },
    },
    List: {
        path: 'users/companies',
        name: 'users_companies_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'UTENTI' }, { label: 'Aziende' }],
            basePath: '/app/users/companies',
        },
    }
}
