const List = () => import('../../views/users/users/List');
const Edit = () => import('../../views/users/users/Edit');

export default {
    Update: {
        path: 'users/users/:id',
        name: 'users_users_edit', //CHANGE
        exact: true,
        props: (route) => {
            return {
                id: parseInt(route.params.id, 10)
            };
        },
        component: Edit,
        meta: {
            basePath: '/app/users/users',
            breadcrumb: [
                { label: 'UTENTI' },
                { label: 'Utenti', to: '/app/users/users'},
                { label: 'Modifica Dati utente' }],
        },
    },
    List: {
        path: 'users/users',
        name: 'users_users_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'UTENTI' }, { label: 'Utenti' }],
        },
    }
}
