const BookPdf = () => import('../../views/cont/books/pdf/Preview');

export default {
    BookPdf: {
        path: 'books/pdf/:id/:lang',
        name: 'cont_books_preview_pdf', //CHANGE
        exact: true,
        props: (route) => {
            return {
                book_id: route.params.id,
                lang: route.params.lang
            };
        },
        component: BookPdf,
    }
}
