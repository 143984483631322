const Search = () => import('../../views/web/domains/View');

export default {
    Search: {
        path: 'web/domains',
        name: 'web_domains', //CHANGE
        exact: true,
        component: Search,
        meta: {
            basePath: '/app/web/domains',
            breadcrumb: [{ label: 'Domains' }],
        },
    }
}