const List = () => import('../../views/tasks/import_purchases/List');
const Edit = () => import ('../../views/tasks/import_purchases/Edit');

export default {
    Create: {
        path: 'tasks/import_purchases/new',
        name: 'tasks_import_purchases_new', //CHANGE
        exact: true,
        component: Edit,
        props: { id: 0 },
        meta: {
            basePath: '/app/tasks/import_purchases',
            breadcrumb: [
                { label: 'TASKS' },
                { label: 'Importazione acquisti EXTRA', to: '/app/tasks/import_purchases' },
                { label: 'Nuova' }],
        },
    },
    List: {
        path: 'tasks/import_purchases',
        name: 'tasks_import_purchases_list',
        exact: true,
        component: List,
        meta: {
            breadcrumb: [{ label: 'TASKS' }, { label: 'Importazione acquisti EXTRA' }],
        },
    }
}
