const config = {
    ep: "v1/macro_categories",
    reqEl: "macro_category",
    respEls: "macro_categories",
    respEl: "macro_category",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale"
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: standardStore.state,
            getters: standardStore.getters,
            mutations: standardStore.mutations,
            actions: standardStore.actions(config)
        }
    }
}
