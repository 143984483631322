import http from '../../../libs/http';

const config = {
    ep: "v1/podcasts",
    reqEl: "podcast",
    respEls: "podcasts",
    respEl: "podcast",
    startLoading: "startLoading",
    stopLoading: "stopLoading",
    storeRows: "setRows",
    storeRow: "setRow",
    localeKey: "locale"
};

export default {
    init(standardStore){
        return {
            namespaced: true,
            state: standardStore.state,
            getters: standardStore.getters,
            mutations: standardStore.mutations,
            actions: {
                ...standardStore.actions(config),
                resetOrder(context, data){
                    context.commit('startLoading');
                    const payload = {
                        [config.respEls]: data
                    };
                    const ep = config['ep'];
                    return new Promise( (resolve, reject ) => {
                        http.api.client.put(ep, JSON.stringify(payload))
                            .then(res => {
                                if (res && res.data && res.data[config.respEls]){
                                    if (config.storeRows) {
                                        context.commit(config.storeRows, res['data'][config.respEls]);
                                    }
                                    resolve(res['data'][config.respEls]);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    })
                }
            }
        }
    }
}
